import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import CompletedWorksTable from "./CompletedWorksTable"
import DateFnsUtils from "@date-io/date-fns"
import DragHandleIcon from "@material-ui/icons/DragHandle"
import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl"
import Grid from "@material-ui/core/Grid"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import PageLayout from "../../components/PageLayout"
import React from "react"
import ReorderIcon from "@material-ui/icons/Reorder"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { observer } from "mobx-react"
import ru from "date-fns/locale/ru"
import { useRootStore } from "../../contexts"

//Изменяем локализацию месяцев, по дефолту они в родительном падеже
let _index = _interopRequireDefault(require("date-fns/locale/_lib/buildLocalizeFn/index.js"))
function _interopRequireDefault(obj:any) { return obj && obj.__esModule ? obj : { default: obj } }
const monthValues = {
    narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
    abbreviated: ["янв.", "фев.", "март", "апр.", "май", "июнь", "июль", "авг.", "сент.", "окт.", "нояб.", "дек."],
    wide: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"]
}
if (ru && ru.localize) {
    ru.localize.month = (_index.default)({
        values: monthValues,
        formattingValues: monthValues,
        defaultWidth: "wide",
        defaultFormattingWidth: "wide"
    })
}
//--

const useStyles = makeStyles({
    root: {
        marginBottom: 60,
    },
    stickToBottom: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0
    },
})
const usePanelStyles = makeStyles({
    root: {
        "&$selected": {
            color: "#000000"
        }
    },
    selected: {

    },
})

function CompletedWorksPage() {
    const { completedWorksStore: ws } = useRootStore()
    const classes = useStyles()
    const panelClasses = usePanelStyles()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        ws.setShowMode(newValue)
        if (newValue !== "filter") {
            ws.clearFilter()
        }
    }

    const handleDateChange = (d:Date|null) => {
        if (!d) {
            d = new Date()
        }
        ws.clearFilter()
        ws.setPeriod(d)
    }

    return (
        <PageLayout title="Завершенные работы" page={CompletedWorksPage} className={classes.root}>
            {ws.filter && <Typography
                component="div"
                variant="h6"
            >
                {!ws.pending && <span>Завершенные работы за {monthValues.wide[ws.month - 1]} {ws.year} с работой {ws.filterName}</span>}
                {ws.pending && <span>Ожидают утверждения работы за {monthValues.wide[ws.month - 1]} {ws.year} с работой {ws.filterName}</span>}
            </Typography>}
            {!ws.filter &&
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Typography
                            component="div"
                            variant="h6"
                            align="right"
                        >
                            Закрытые наряды:
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                            <DatePicker
                                cancelLabel={"Отмена"}
                                format="MMMM yyyy"
                                openTo={"month"}
                                views={["year", "month"]}
                                minDate={new Date("2021-01-01")}
                                maxDate={new Date()}
                                value={ws.period}
                                autoOk={true}
                                onChange={handleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            }

            <CompletedWorksTable />

            <BottomNavigation
                value={ws.showMode}
                onChange={handleChange}
                showLabels
                className={classes.stickToBottom}
            >
                <BottomNavigationAction label="Подробно" value={"full"} icon={<ReorderIcon />} classes={panelClasses} />
                <BottomNavigationAction label="Кратко" value={"small"} icon={<DragHandleIcon />} classes={panelClasses} />
                <BottomNavigationAction label="Отчет" value={"report"} icon={<FormatListNumberedRtlIcon />} classes={panelClasses} />
                {ws.filter && <BottomNavigationAction label="Фильтр" value={"filter"} icon={<MenuOpenIcon />} classes={panelClasses} />}
            </BottomNavigation>
        </PageLayout>
    )
}

export default observer(CompletedWorksPage)