import { AccountBox, AddBox, Assignment, AssignmentTurnedIn, Notifications } from "@material-ui/icons"
import { Card, CardContent, CircularProgress, List, ListItem, ListItemText } from "@material-ui/core"
import React, { useState } from "react"
import ListCommandItem from "./ListCommandItem"
import LoadingDocuments from "../../components/RegSearch/LoadingDocuments"
import { NarrowContainer } from "@react-force/core"
import PageLayout from "../../components/PageLayout"
import SearchIcon from "@material-ui/icons/Search"
import UserInfo from "../../components/UserInfo"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function SearchBlock() {
    return (
        <Card>
            <CardContent>
                <LoadingDocuments />
            </CardContent>
        </Card>
    )
}
function HomePage() {
    const { authStore, searchStore, notificationsStore } = useRootStore()
    const [showSearch, setShowSearch] = useState(false)

    const handleShowSearch = (d:boolean) => {
        searchStore.clearSearch()
        setShowSearch(d)
    }

    const count = notificationsStore.notificationInfo ? notificationsStore.notificationInfo?.UnreadNotifications.length : 0

    let notifications = "Уведомления"
    if (authStore.user && count) {
        notifications = `${notifications} (${count})`
    }

    return (
        <PageLayout title="Личный кабинет ЗТЛ" backButton={false} homeButton={false} page={HomePage}>
            <NarrowContainer p={2}>
                {authStore.user && <UserInfo />}

                <List>
                    {!authStore.user && (
                        <ListCommandItem
                            text={authStore.working ? "Проверка входа..." : "Войти в личный кабинет"}
                            route="login"
                            icon={authStore.working ? <CircularProgress size={24} color="inherit" /> : <AccountBox />}
                        />
                    )}
                    {authStore.user && (
                        <React.Fragment>
                            <ListCommandItem
                                text="Регистрация новой работы"
                                route="registrationSearсh"
                                icon={<AddBox />}
                            />
                            <ListCommandItem
                                text="Текущие работы"
                                route="currentWorks"
                                icon={<Assignment />}
                            />
                            <ListCommandItem
                                text="Завершенные работы"
                                route="completedWorks"
                                icon={<AssignmentTurnedIn />}
                            />
                            <ListCommandItem
                                text={notifications}
                                route="notifications"
                                icon={<Notifications />}
                            />
                            <ListItem button={true}>
                                <SearchIcon />
                                <ListItemText
                                    onClick={() => handleShowSearch(true)}
                                    primary={"Поиск наряда по номеру"}
                                    style={{ marginLeft: 10 }}
                                />
                            </ListItem>
                        </React.Fragment>
                    )}

                </List>
                {authStore.user && showSearch && <SearchBlock/>}
            </NarrowContainer>
        </PageLayout>
    )
}

export default observer(HomePage)
