import { Card, CardContent } from "@material-ui/core"
import React, {useEffect } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import ErrorIcon from "@material-ui/icons/Error"
import Grid from "@material-ui/core/Grid"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import OrderName from "../../components/OrderName"
import TouchAppIcon from "@material-ui/icons/TouchApp"
import Typography from "@material-ui/core/Typography"
import { green } from "@material-ui/core/colors"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginBottom: 10,
        },
        child: {
            padding: 0,
            paddingBottom: "0!important",
        },
        title: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        nested: {
            padding: 0,
            paddingLeft: theme.spacing(1),
        },
        icon: {
            minWidth: 28,
            marginTop:  theme.spacing(0.5),
        }
    }),
)

function CurrentWorksTable() {
    const { currentWorksStore, routerStore } = useRootStore()
    const workDocuments = currentWorksStore.workDocuments
    const classes = useStyles()

    const refs = workDocuments?.reduce((acc:any, value) => {
        acc[value.DocumentID] = React.createRef()
        return acc
    }, {})

    useEffect(() => {
        if (refs[currentWorksStore.scrollTo]) {
            refs[currentWorksStore.scrollTo].current.scrollIntoView({
                block: "start",
            })
        }
    }, [currentWorksStore, refs])

    if (!workDocuments || workDocuments.length === 0) {
        return null
    }

    const handleEntityClicked = (id:number) => {
        routerStore.goTo("order", {
            params: {
                orderNumber: id
            }
        })
    }

    let checkIcon = (state:number) => {
        switch (state) {
        case 0:
            return (
                <TouchAppIcon color="secondary" />
            )
        case 1:
            return (
                <HourglassEmptyIcon color="primary" />
            )
        case 2:
            return (
                <div />
            )
        case 3:
            return (
                <ErrorIcon color="secondary" />
            )
        case 4:
            return (
                <CheckIcon style={{ color: green[500] }}/>
            )
        }
        return (
            <div />
        )
    }

    return (
        <div>
            <List>
                {workDocuments.map((item, k) => (
                    <Card className={classes.root} key={k} ref={refs[item.DocumentID]}>
                        <CardContent className={classes.child}>
                            <ListItem className={classes.title} onClick={() => handleEntityClicked(item.DocumentID)}>
                                <ListItemText
                                    primary={<OrderName name={item.MedicalOrderNumber} code={item.MedicalOrderID}/>}
                                />
                            </ListItem>
                            <List disablePadding>
                                {item.Works.map((work, i) => (
                                    <ListItem className={classes.nested} key={i} alignItems="flex-start">
                                        <ListItemIcon className={classes.icon}>
                                            {checkIcon(work.StateID)}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Grid container spacing={1}>
                                                    <Grid item xs={10}>
                                                        {work.WorkName}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.title}>
                                                        <Typography
                                                            align="right"
                                                            className={classes.title}
                                                        >
                                                            {work.Quantity}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                ))}
            </List>
        </div>
    )
}

export default observer(CurrentWorksTable)
