import React, { Fragment } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import { WorkGroup } from "../../services/GetTechnicianWorksDocumentsHistory"
import { observer } from "mobx-react"

interface iProps {
    documents: WorkGroup[],
    documents2: WorkGroup[],
    classes: any,
    onClick: any,
    filter?: any,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            paddingLeft: theme.spacing(4),
        },
        noList: {
            padding: theme.spacing(4),
            color: "#999999"
        }
    }),
)

function ViewModeReport(props: iProps) {
    const classes = useStyles()

    return (
        <Fragment>
            {props.documents.length === 0 && <div className={classes.noList}>Нет нарядов в данной категории</div>}
            {props.documents.length > 0 &&
            <List>
                {props.documents.map((item, k) => (
                    <ListItem className={props.classes.titleBottom} key={k}>
                        <ListItemText
                            primary={
                                <Grid container spacing={1}>
                                    <Grid item xs={10}>
                                        <Typography
                                            component="div"
                                            variant="body1"
                                            color="primary"
                                            onClick={() => props.onClick(item.WorkID, item.WorkName, false)}
                                        >
                                            {item.WorkName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            component="div"
                                            variant="body1"
                                            align="right"
                                        >
                                            {item.Quantity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            }

            {props.documents2.length > 0 && <React.Fragment>
                <div className={classes.header}>
                    <Typography
                        component="div"
                        variant="h6"
                        align="left"
                    >
                        Ожидают утверждения:
                    </Typography>
                </div>

                <List>
                    {props.documents2.map((item, k) => (
                        <ListItem className={props.classes.titleBottom} key={k}>
                            <ListItemText
                                primary={
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Typography
                                                component="div"
                                                variant="body1"
                                                color="primary"
                                                onClick={() => props.onClick(item.WorkID, item.WorkName, true)}
                                            >
                                                {item.WorkName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                component="div"
                                                variant="body1"
                                                align="right"
                                            >
                                                {item.Quantity}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </React.Fragment>
            }
        </Fragment>
    )
}

export default observer(ViewModeReport)