import CheckIcon from "@material-ui/icons/Check"
import ErrorIcon from "@material-ui/icons/Error"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import PersonIcon from "@material-ui/icons/Person"
import React from "react"
import { green } from "@material-ui/core/colors"
import { observer } from "mobx-react"

interface iProps {
    state: number,
}

function StateIcon(props: iProps) {
    switch (props.state) {
    case 0:
        return (
            <PersonIcon color="disabled" />
        )
    case 1:
        return (
            <HourglassEmptyIcon color="primary" />
        )
    case 2:
        return (
            <div />
        )
    case 3:
        return (
            <ErrorIcon color="secondary" />
        )
    case 4:
        return (
            <CheckIcon style={{ color: green[500] }}/>
        )
    }
    return (
        <div />
    )
}

export default observer(StateIcon)