import { Header as BaseHeader, HeaderTitle } from "@react-force/core"
import { BackButton } from "./BackButton"
import HeaderMenu from "./HeaderMenu"
import HomeButton from "./HomeButton"
import NotificationButton from "./NotificationButton"
import React from "react"
import { observer } from "mobx-react-lite"

export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: string
    page?: () => any
}

function CustomHeader(props: IProps) {
    const backButton = props.backButton === undefined || props.backButton
    const homeButton = props.homeButton === undefined || props.homeButton

    const handleClick = (e: React.MouseEvent) => {
        if (e.detail === 3 && props.page) {
            alert("Page component name: " + props.page.name)
        }
    }

    return (
        <BaseHeader>
            {backButton && <BackButton />}
            <HeaderTitle>
                <span onClick={handleClick}>
                    {props.title}
                </span>
            </HeaderTitle>
            {homeButton && <HomeButton />}
            <NotificationButton />
            <HeaderMenu />
        </BaseHeader>
    )
}

export const Header = observer(CustomHeader)
