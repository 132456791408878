// Manages completed works and salary calculation periods for the CompletedWorksPage

import {
    GetTechnicianWorksDocumentsHistory,
    WorkGroup,
    TechnicianWorksDocumentsHistory as Works
} from "../services/GetTechnicianWorksDocumentsHistory"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class CompletedWorksStore {
    rootStore: RootStore
    works?: Works[] = []
    filtered?: Works[] = []
    reports: WorkGroup[] = []
    reportsPending: WorkGroup[] = []
    month: number
    year: number
    period?: Date
    showMode = "full"
    filter: any
    filterName = ""
    scrollTo = 0
    pending = false

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            works: observable.ref,
            rootStore: false,
            showMode: observable,
            scrollTo: observable,
        })

        let dt = new Date()

        this.month = dt.getMonth() + 1
        this.year = dt.getFullYear()

        this.rootStore = rootStore

        reaction(() => ({month: this.month, year: this.year}), async () => {
            await this.loadWorks()
        })
    }

    setScrollTo = (id: number) => {
        this.scrollTo = id
    }
    clearScrollTo = () => {
        this.scrollTo = 0
    }

    setFilter = (filter: any, filterName: string, pending: boolean) => {
        this.pending = pending
        this.filter = filter
        this.filterName = filterName
        this.showMode = "filter"
        this.filtered = []

        this.works?.filter(x => x.Pending === pending).forEach((item) => {
            item.Works?.forEach((work) => {
                if (work.WorkID === filter) {
                    let ex = false
                    this.filtered?.forEach((wr) => {
                        if (wr.DocumentID === item.DocumentID) {
                            ex = true
                        }
                    })
                    if (!ex) {
                        this.filtered?.push(item)
                    }
                }
            })
        })
    }
    clearFilter = () => {
        this.filtered = []
        this.filter = undefined
        this.filterName = ""
    }

    setShowMode = (mode: string) => {
        this.showMode = mode
    }

    defaultShowMode = () =>
        this.showMode = "full"

    setPeriod = (date: Date | null) => {
        if (!date) {
            date = new Date()
        }
        this.period = date
        this.month = date.getMonth() + 1
        this.year = date.getFullYear()
    }

    setWorks = (w: Works[]) => {
        this.works = w
        this.reports = []
        this.reportsPending = []
        w.forEach((item) => {
            if (item.Pending === true) {
                item.Works?.forEach((work) => {
                    let ex = false
                    this.reportsPending.forEach((wr) => {
                        if (wr.WorkID === work.WorkID) {
                            wr.Quantity = wr.Quantity + work.Quantity
                            ex = true
                        }
                    })
                    if (!ex) {
                        let wrk = {} as WorkGroup
                        wrk = work
                        this.reportsPending.push(wrk)
                    }
                })
            } else {
                item.Works?.forEach((work) => {
                    let ex = false
                    this.reports.forEach((wr) => {
                        if (wr.WorkID === work.WorkID) {
                            wr.Quantity = wr.Quantity + work.Quantity
                            ex = true
                        }
                    })
                    if (!ex) {
                        let wrk = {} as WorkGroup
                        wrk = work
                        this.reports.push(wrk)
                    }
                })
            }

        })
        w?.sort((a, b) => (a.MedicalOrderID > b.MedicalOrderID) ? 1 : ((b.MedicalOrderID > a.MedicalOrderID) ? -1 : 0))
        this.reports?.sort((a, b) => (a.Quantity < b.Quantity) ? 1 : ((b.Quantity < a.Quantity) ? -1 : 0))
        this.reportsPending?.sort((a, b) => (a.Quantity < b.Quantity) ? 1 : ((b.Quantity < a.Quantity) ? -1 : 0))
    }

    clearWorks = () =>
        this.works = undefined

    loadWorks = async () => {
        try {
            const w = await GetTechnicianWorksDocumentsHistory(this.year, this.month)
            this.setWorks(w)
        } catch {
            this.clearWorks()
        }
    }
}
