import { IReturn } from "ultima-client"
import { call } from "./websockets"

export class Request implements IReturn<Response> {
    getTypeName = () => "GetTechnicianCommonInfo"
    createResponse = () => new Response()
}

export class Response {
    EmployeeName!: string
    TechnicianSpecializationName!: string
    NotificationCount!: number
    OfficeShortNames!: string[]
}

export function GetTechnicianCommonInfo() {
    return call(new Request())
}
