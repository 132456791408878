import { List, ListItem, ListItemText } from "@material-ui/core"
import { Button } from "@material-ui/core"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import Moment from "moment"
import { ResponseItem as Notification } from "../../services/GetEmployeeNotifications"
import PageLayout from "../../components/PageLayout"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { grey } from "@material-ui/core/colors"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function NotificationsPage() {
    const { notificationsStore, routerStore } = useRootStore()
    const [open, setOpen] = React.useState({
        dialogOpen: false,
        selectedElement: {} as Notification
    })

    const handleEntityClicked = (id:number) => {
        routerStore.goTo("notification", {
            params: {
                notificationId: id
            }
        })
    }

    const removeNotification = () => {
        notificationsStore.removeNotification(false)
        setOpen({
            dialogOpen: false,
            selectedElement: {} as Notification
        })
    }

    const handleClose = () => {
        setOpen({
            dialogOpen: false,
            selectedElement: {} as Notification
        })
    }

    const setNotification = (id: number) => {
        notificationsStore.setNotification(id)
        setOpen({
            dialogOpen: true,
            selectedElement: notificationsStore.notification ? notificationsStore.notification : {} as Notification
        })
    }

    return (
        <PageLayout title="Уведомления" page={NotificationsPage}>
            <Dialog
                open={open.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Удалить уведомление?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {open.selectedElement.Caption}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        Нет
                    </Button>
                    <Button color="primary" onClick={removeNotification}>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
            {notificationsStore.notifications.length === 0 &&
                <Typography
                    variant="body2"
                    color="textSecondary"
                    display="block"
                >
                    У Вас нет новых уведомлений.
                </Typography>
            }
            {notificationsStore.notifications.length > 0 && <List>
                {notificationsStore.notifications.map(n => (
                    <ListItem key={n.ID}>
                        <ListItemText
                            primary={<Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography
                                        variant="body2"
                                        color={n.Unread ? "textPrimary" : "textSecondary"}
                                        display="block"
                                    >
                                        {Moment(n.RelevanceDate).format("DD.MM.YY")}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color={n.Unread ? "textPrimary" : "textSecondary"}
                                        display="block"
                                    >
                                        {Moment(n.RelevanceDate).format("HH:mm")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        variant={n.Unread ? "subtitle2" : "body2"}
                                        color={n.Unread ? "textPrimary" : "textSecondary"}
                                        display="block"
                                        onClick={() => handleEntityClicked(n.ID)}
                                    >
                                        {n.Caption}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {!n.Unread && <DeleteOutlineIcon onClick={() => setNotification(n.ID)}></DeleteOutlineIcon>}
                                    {n.Unread && <DeleteOutlineIcon style={{color: grey[300]}}></DeleteOutlineIcon>}
                                </Grid>
                            </Grid>}
                        />
                    </ListItem>
                ))}
            </List>}
        </PageLayout>
    )
}

export default observer(NotificationsPage)
