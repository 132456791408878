import { call } from "./websockets"

export interface Response {
    Caption: string
    Category: string
    EmployeeID: number
    EmployeeNotificationTypeID: number
    ID: number
    NotificationEvent: string
    NotificationHtml: string
    NotificationText: string
    RelevanceDate: Date
    Unread: boolean
}

export async function GetImmediateNotifications() {
    return await call<Response[]>("GetImmediateNotifications")
}
