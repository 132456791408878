import { Card, CardContent } from "@material-ui/core"
import React, { Fragment } from "react"
import { Button } from "@material-ui/core"
import LoadingOrder from "../../components/LoadingOrder"
import PageLayout from "../../components/PageLayout"
import RegEditWorksTable from "./RegEditWorksTable"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function RegEditWorksPage() {
    const { callStatusStore, routerStore, regEditWorksStore } = useRootStore()
    const { orderNumber } = routerStore.routerState.params

    const { orderDocument: doc } = regEditWorksStore
    if (!doc|| !doc.Reworks || !doc.Reworks.length) {
        return null
    }

    const loading = !regEditWorksStore.orderDocument && callStatusStore.callInProgress
    const loaded = regEditWorksStore.orderDocument
    const state = doc.MedicalOrderNumberCloseDate || false

    const goToOrder = () => {
        routerStore.goTo("order", {
            params: {
                orderNumber: doc.DocumentID
            }
        })
    }

    return (
        <PageLayout title="Регистрация переделок" page={RegEditWorksPage}>
            {loading && <LoadingOrder orderNumber={orderNumber} />}
            {loaded && !state && <RegEditWorksTable/>}
            {state && <Fragment>
                <Card>
                    <CardContent>
                        Наряд уже закрыт. Перейти к просмотру наряда?
                    </CardContent>
                </Card>
                <br/>
                <Button variant="contained" color="primary" onClick={goToOrder}>
                    Открыть наряд
                </Button>
            </Fragment>}
        </PageLayout>
    )
}

export default observer(RegEditWorksPage)