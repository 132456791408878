// Describes the supported routes, their parameters and event handlers

import { RouterState, RouterStore, createRouterState } from "mobx-state-router"
import { RootStore } from "./RootStore"
import { enableReWork } from "../services/constants"

const loginState = createRouterState("login")

// router state change handler that regirects a user to the login page
const checkForUserLoggedIn = async (
    fromState: RouterState,
    toState: RouterState,
    routerStore: RouterStore
) => {
    const {rootStore} = routerStore.options
    const {authStore} = rootStore as RootStore

    console.log("Checking whether the user is logged in...")
    if (!authStore.user) {
        //console.log("Not logged in, trying to restore the last saved session")
        //await authStore.relogin()

        if (!authStore.user) {
            console.warn("Still not logged in, redirecting to the login page.")
            authStore.setLoginRedirect(toState)
            return loginState
        }
    }
    checkNotification(rootStore)
}

// router state change handler that loads the order into the RegEditWorksStore
const regLoadOrderByNumber = async (
    fromState: RouterState,
    toState: RouterState,
    routerStore: RouterStore,
) => {
    const {rootStore} = routerStore.options

    const {regEditWorksStore} = rootStore as RootStore
    const {orderNumber} = toState.params

    if (enableReWork) {
        regEditWorksStore.clearRegistered()
        regEditWorksStore.setReWork()
        if (regEditWorksStore.orderNumber !== orderNumber) {
            regEditWorksStore.setOrderNumber(orderNumber)
        } else if (!regEditWorksStore.orderDocument) {
            regEditWorksStore.loadOrder()
        }
    } else {
        routerStore.goTo("home")
    }
}

const checkNotification = async (rootStore: any) => {
    const {notificationsStore} = rootStore as RootStore
    notificationsStore.checkNotifications()
}

export const routes = [
    {
        name: "home",
        pattern: "/",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options

            const {searchStore} = rootStore as RootStore

            searchStore.clearOrderNumber()
            searchStore.clearOrderCode()
            searchStore.clearOrderDocument()
            searchStore.clearSearch()
            searchStore.disableRegMode()
        }
    },
    {
        name: "login",
        pattern: "/login",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {authStore} = rootStore as RootStore
            if (!authStore.loginRedirect) {
                authStore.setLoginRedirect(createRouterState("home"))
            }
        }
    },
    {
        name: "completedWorks",
        pattern: "/works/completed",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options

            const {completedWorksStore} = rootStore as RootStore
            if (fromState.routeName === "order") {
                completedWorksStore.setScrollTo(Number(fromState.params.orderNumber))
            } else {
                completedWorksStore.clearScrollTo()
            }
            completedWorksStore.loadWorks()
        }
    },
    {
        name: "currentWorks",
        pattern: "/works/current",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options

            const {currentWorksStore} = rootStore as RootStore
            if (fromState.routeName === "order") {
                currentWorksStore.setScrollTo(Number(fromState.params.orderNumber))
            } else {
                currentWorksStore.clearScrollTo()
            }
            currentWorksStore.loadWorks()
        }
    },
    {
        name: "order",
        pattern: "/orders/:orderNumber",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options

            const {orderWorksStore, regEditWorksStore} = rootStore as RootStore
            const {orderNumber} = toState.params
            orderWorksStore.clearError()
            orderWorksStore.clearSuccess()

            if (fromState.routeName === "registrationEdit" && regEditWorksStore.registered) {
                orderWorksStore.setRegistered()
                orderWorksStore.loadOrder()
            } else {
                orderWorksStore.clearRegistered()
            }

            if (orderWorksStore.orderNumber !== orderNumber) {
                orderWorksStore.setOrderNumber(orderNumber)
            } else if (!orderWorksStore.orderDocument) {
                orderWorksStore.loadOrder()
            }
        }
    },
    {
        name: "registrationSearсh",
        pattern: "/regs/search",
        beforeEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            checkForUserLoggedIn(fromState, toState, routerStore)
            const {rootStore} = routerStore.options

            const {searchStore} = rootStore as RootStore

            searchStore.clearOrderNumber()
            searchStore.clearOrderCode()
        }
    },
    {
        name: "registrationEdit",
        pattern: "/regs/:orderNumber",
        beforeEnter: checkForUserLoggedIn,
        onEnter: regLoadOrderByNumber,
    },
    {
        name: "notifications",
        pattern: "/notifications",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore,
        ) => {
            const {rootStore} = routerStore.options

            const {notificationsStore} = rootStore as RootStore
            notificationsStore.clearNotification()
            notificationsStore.loadNotifications()
        }
    },
    {
        name: "notification",
        pattern: "/notification/:notificationId",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore,
        ) => {
            const {rootStore} = routerStore.options

            const {notificationsStore} = rootStore as RootStore
            const {notificationId} = toState.params
            notificationsStore.setNotification(Number(notificationId))
            if (!notificationsStore.notifications) {
                notificationsStore.loadNotifications()
            }
        }
    },
    {
        name: "settings",
        pattern: "/settings",
    },
    {
        name: "notFound",
        pattern: "/not-found",
    },
    {
        name: "help",
        pattern: "/help",
    },
]