import React, {Fragment, useState} from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import KeyBoard from "../../components/RegSearch/KeyBoard"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import RegSearch from "../../components/RegSearch/RegSearch"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            paddingBottom: 0,
            marginBottom: 0,
        },
        root: {
            padding: theme.spacing(1),
        },
        icon: {
            minWidth: 28
        },
        bold: {
            fontWeight: 600
        },
        button: {
            marginRight: 10,
            marginBottom: 10,
        },
        button2: {
            marginTop: 10,
            marginBottom: 10,
        },
    }))

interface iProps {
    buttonLabel?: string,
    regMode?: boolean
}
function LoadingDocuments(props: iProps) {
    const { callStatusStore, searchStore, authStore, routerStore } = useRootStore()
    const classes = useStyles()

    const [value, setValue] = useState(searchStore?.orderNumber || "")
    const enabled = value.replace(/[\s_.-]/g, "").length > 8
    const offices = (authStore.user && authStore.user.offices) ? authStore.user.offices.slice().sort() : []

    if (props.regMode)
        searchStore.setRegMode()

    const updateData = (value:string) => {
        setValue(value)
    }

    const findByCode = (code:string) => {
        searchStore.startSearch()
        searchStore.setOrderCode(code)
    }

    const getPrimary = (text: any) => {
        return (
            <Typography
                variant="body1"
                color="textPrimary"
                display="block"
            >
                {text}
            </Typography>
        )
    }
    const getSecondary = (text: any) => {
        return (
            <Typography
                variant="body2"
                color="textSecondary"
                display="block"
            >
                {text}
            </Typography>
        )
    }
    const handleSubmit = () => {
        searchStore.startSearch()
        searchStore.setOrderNumber(value.replace("_", ""))
    }
    const getTechnicians = (technicians:any) => {
        return (
            <Fragment>
                {technicians && technicians.map((technic:any, i:any) => (
                    <Typography
                        key={i}
                        className={technic.IsResponsible ? classes.bold : ""}
                        variant="body1"
                        color="textPrimary"
                        display="block"
                    >
                        {technic.Name}
                    </Typography>
                ))}
            </Fragment>
        )
    }

    let content = (<Fragment></Fragment>)

    if (callStatusStore.callInProgress) {
        content = (
            <Typography
                variant="body2"
                color="textPrimary"
                display="block"
            >
                Выполняется поиск...
            </Typography>
        )
    } else {
        if(searchStore.orderDocument && searchStore.orderDocument.DocumentID && searchStore.regMode && !searchStore.orderDocument.CanRegisterWork) {
            if (searchStore.orderDocument?.CanNavigate) {
                const handleOrder = () => {
                    routerStore.goTo("order", {
                        params: {
                            orderNumber: searchStore.orderDocument?.DocumentID
                        }
                    })
                }
                content = (
                    <React.Fragment>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                            display="block"
                        >
                            Работы по этому наряду уже зарегистрированы.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button2}
                            fullWidth={true}
                            onClick={handleOrder}
                        >
                            Открыть наряд
                        </Button>
                    </React.Fragment>
                )
            } else {
                content = (
                    <Typography
                        variant="body2"
                        color="secondary"
                        display="block"
                    >
                        В найденном наряде нет работ, доступных для регистрации.
                    </Typography>
                )
            }
        } else if (searchStore.searchInit && searchStore.orderDocument && searchStore.orderDocument.DocumentID) {
            content = (
                <Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Typography
                                component="div"
                                variant="h6"
                            >
                                {searchStore.orderDocument?.MedicalOrderNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography
                                component="div"
                                variant="h6"
                                align="right"
                            >
                                {searchStore.orderDocument?.MedicalOrderID}
                            </Typography>
                        </Grid>
                    </Grid>

                    <List>
                        <ListItem className={classes.item}>
                            <ListItemText
                                className={classes.item}
                                primary={getSecondary("Врач")}
                                secondary={getPrimary(searchStore.orderDocument?.Doctor)}
                            />
                        </ListItem>
                        <ListItem className={classes.item}>
                            <ListItemText
                                className={classes.item}
                                primary={getSecondary("Пациент")}
                                secondary={getPrimary(searchStore.orderDocument?.Patient)}
                            />
                        </ListItem>
                        <ListItem className={classes.item}>
                            <ListItemText
                                className={classes.item}
                                primary={getSecondary("Техники")}
                                secondary={getTechnicians(searchStore.orderDocument?.Technicians)}
                            />
                        </ListItem>
                    </List>
                </Fragment>

            )
        } else if (searchStore.searchInit) {
            content = (
                <Typography
                    variant="body2"
                    color="secondary"
                    display="block"
                >
                    Наряд с указанным номером не найден, проверьте правильность введенных данных.
                </Typography>
            )
        }
    }

    return (
        <Fragment>
            <KeyBoard
                label={"Введите код наряда"}
                updateData={findByCode}
            />
            <RegSearch
                label={"или номер наряда"}
                updateData={updateData}
                offices={offices}
            />
            <div style={{ marginTop: "1em" }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!enabled}
                    onClick={handleSubmit}
                    className={classes.button}
                    fullWidth={true}
                >
                    {props.buttonLabel ? props.buttonLabel : "Найти"}
                </Button>
                {content}
            </div>
        </Fragment>
    )
}

export default observer(LoadingDocuments)
