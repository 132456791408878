import { call } from "./websockets"

export interface Response {
    EmployeeID: number
    UnreadNotifications: number[]
    Alerts: number[]
    Notices: number[]
    Infos: number[]
}

export async function GetNotificationsInfo() {
    return await call<Response>("GetNotificationsInfo")
}
