// Tracks remote call execution progress
// Stores the last error so it can be displayed in a popup message

import { RootStore } from "./RootStore"
import { makeAutoObservable } from "mobx"
import { setTrackingCallbacks } from "../services/websockets"

export class CallStatusStore {
    rootStore: RootStore
    callLevel = 0
    lastError?: any

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
        setTrackingCallbacks({
            startLoading: this.startCall,
            stopLoading: this.stopCall,
            setLastError: this.setLastError,
            clearLastError: this.clearLastError,
        })
    }

    startCall = () => {
        this.callLevel++
        if (this.callLevel > 5) {
            throw new Error("Too many parallel calls. Most likely, it's a circular update caused by autorun.")
        }
    }

    stopCall = () =>
        this.callLevel--

    get callInProgress() {
        return this.callLevel > 0
    }

    setLastError = (e: any) =>
        this.lastError = e

    clearLastError = () =>
        this.lastError = undefined
}