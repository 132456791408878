import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import CurrentWorksTable from "./CurrentWorksTable"
import PageLayout from "../../components/PageLayout"
import React from "react"
import SortIcon from "@material-ui/icons/Sort"
import { makeStyles } from "@material-ui/core/styles"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles({
    root: {
        marginBottom: 60,
    },
    stickToBottom: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0
    },
    rotate: {
        transform: "rotate(-180deg)",
    }
})
const usePanelStyles = makeStyles({
    root: {
        "&$selected": {
            color: "#000000"
        }
    },
    selected: {

    },
})

function CurrentWorksPage() {
    const { currentWorksStore } = useRootStore()
    const classes = useStyles()
    const panelClasses = usePanelStyles()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        currentWorksStore.setSortBy(newValue)
    }

    return (
        <PageLayout title="Текущие работы" page={CurrentWorksPage} className={classes.root}>
            <CurrentWorksTable />
            <BottomNavigation
                value={currentWorksStore.sortBy}
                onChange={handleChange}
                showLabels
                className={classes.stickToBottom}
            >
                <BottomNavigationAction label="Сначала старые" value={"asc"} icon={<SortIcon className={classes.rotate} />} classes={panelClasses} />
                <BottomNavigationAction label="Сначала новые" value={"desc"} icon={<SortIcon />} classes={panelClasses} />
            </BottomNavigation>
        </PageLayout>
    )
}

export default observer(CurrentWorksPage)