import { GetTechnicianWorksDocumentStatus, Response as OrderStatus } from "../services/GetTechnicianWorksDocumentStatus"
import { RootStore } from "./RootStore"
import { makeAutoObservable } from "mobx"

export class RegSearchOrderStore {
    rootStore: RootStore
    orderNumber?: string
    orderStatus?: OrderStatus

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }

    setOrderNumber = async (orderNumber: string) => {
        this.orderNumber = orderNumber
        this.orderStatus = undefined
        await this.loadOrderStatus()
    }

    clearOrderNumber = () => {
        this.orderNumber = undefined
        this.orderStatus = undefined
    }

    setOrderStatus = (orderStatus: OrderStatus) => {
        this.orderStatus = orderStatus
    }

    loadOrderStatus = async () => {
        const { routerStore } = this.rootStore

        try {
            const s = await GetTechnicianWorksDocumentStatus(this.orderNumber || "")
            this.setOrderStatus(s)

            // ok => redirect to registration of existing works
            if (s?.OK) {
                routerStore.goTo("registrationEdit", {
                    params: {
                        orderNumber: this.orderNumber
                    }
                })
            }
        } catch (e) {
            this.setOrderStatus({
                NotFound: true,
                Comments: e.toString(),
            })
        }
    }
}
