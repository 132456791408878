import BackspaceIcon from "@material-ui/icons/Backspace"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Paper from "@material-ui/core/Paper"
import React from "react"
import Style from "./RegSearch.module.css"

type IKeyBoardProps = {
    updateData: any,
    label: string | null,
}

class KeyBoard extends React.Component<IKeyBoardProps> {
    state = {
        open: false,
        keys: ["1","2","3","4","5","6","7","8","9","bs","0","cl"],
        number: "",
    }

    labelKey = (key:string) => {
        if (key === "bs") {
            return (<BackspaceIcon className={Style.icon}/>)
        } else if (key === "cl") {
            return (<HighlightOffIcon className={Style.icon}/>)
        } else {
            return key
        }
    }

    handleKey = (key:string) => {
        if (key === "bs") {
            this.setState({number: this.state.number.slice(0, -1)})
        } else if (key === "cl") {
            this.setState({number: ""})
        } else {
            this.setState({number: this.state.number + key})
        }
    }

    CodeDialogRaw = () => {
        return (
            <Dialog open={this.state.open}>
                <DialogTitle>Ввод кода</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className={Style.input}>{this.state.number}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={this.state.number.length === 0}
                                className={Style.send}
                                onClick={() => {
                                    this.props.updateData(this.state.number)
                                    this.setState({open: false, number: ""})
                                }}>Ввод</Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        {this.state.keys.map((row, i) =>
                            <Grid item xs={4} className={Style.grid} key={i}>
                                <Paper onClick={() => this.handleKey(row)}>
                                    <div className={Style.keyboard}>{this.labelKey(row)}</div>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    <br/>
                    <Button variant="contained" color="default" fullWidth={true} onClick={() => {
                        this.setState({number: ""})
                        this.setState({open: false})
                    }}>Отмена</Button>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <div>
                {this.CodeDialogRaw()}
                <div>{this.props.label}</div>
                <div className={Style.input} onClick={() => this.setState({open: true})}>{this.state.number}</div>
            </div>
        )
    }
}

export default KeyBoard