import { Card, TextField } from "@material-ui/core"
import React, { useState } from "react"
import { ProgressButton } from "@react-force/core"
import SettingsButton from "../../components/Header/SettingsButton"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function LoginCard() {
    const { authStore, callStatusStore } = useRootStore()
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    const handleLoginChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setLogin(event.currentTarget.value)

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setPassword(event.currentTarget.value)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.stopPropagation()
        event.preventDefault()
        await authStore.login(login, password)
    }

    return (
        <Card>
            <form onSubmit={handleSubmit} style={{ margin: 10 }}>
                <TextField
                    value={login}
                    onChange={handleLoginChange}
                    fullWidth={true}
                    margin="dense"
                    label="Логин"
                />

                <TextField
                    value={password}
                    onChange={handlePasswordChange}
                    fullWidth={true}
                    type="password"
                    margin="dense"
                    label="Пароль"
                />

                <div style={{ marginTop: "1em" }}>
                    <ProgressButton
                        busy={callStatusStore.callInProgress || authStore.working}
                        variant="contained"
                        type="submit"
                        color="primary"
                    >
                        Вход
                    </ProgressButton>
                    <SettingsButton />
                </div>
            </form>
        </Card>
    )
}

export default observer(LoginCard)
