// Manages the list of current works for the CurrentWorksPage

import { ActualTechnicianWorksDocument, GetActualTechnicianWorksDocuments } from "../services/GetActualTechnicianWorksDocuments"
import { makeAutoObservable, observable } from "mobx"
import { RootStore } from "./RootStore"

export class CurrentWorksStore {
    rootStore: RootStore
    workDocuments?: ActualTechnicianWorksDocument[] = []
    sortBy = "asc"
    scrollTo = 0

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            workDocuments: observable,
            rootStore: false,
            sortBy: observable,
            scrollTo: observable,
        })

        this.rootStore = rootStore
    }

    setSortBy = (s: string) => {
        this.sortBy = s
        let w = this.workDocuments
        if (this.sortBy === "desc") {
            w?.sort((a,b) => (a.MedicalOrderID < b.MedicalOrderID) ? 1 : ((b.MedicalOrderID < a.MedicalOrderID) ? -1 : 0))
        } else {
            w?.sort((a,b) => (a.MedicalOrderID > b.MedicalOrderID) ? 1 : ((b.MedicalOrderID > a.MedicalOrderID) ? -1 : 0))
        }
        this.workDocuments = w
    }

    setWorks = (w: ActualTechnicianWorksDocument[]) => {
        this.workDocuments = w
        this.setSortBy(this.sortBy)
    }

    setScrollTo = (id: number) => {
        this.scrollTo = id
    }
    clearScrollTo = () => {
        this.scrollTo = 0
    }

    defaultSort = () =>
        this.sortBy = "asc"

    clearWorks = () =>
        this.workDocuments = undefined

    loadWorks = async () => {
        try {
            const w = await GetActualTechnicianWorksDocuments()
            this.setWorks(w)
        } catch {
            this.clearWorks()
        }
    }
}
