import { call } from "./websockets"

export interface Work{
    Quantity: number
    QuantityForRegistration: number
    StateID: number
    StateName: string
    WorkID: number
    WorkName: string
    WorkTypeID: number
    WorkTypeName: string

    Checked: boolean
    ConfirmedQuantity: number
}

export interface ConfirmableWorks {
    WorkID: number
    WorkName: string

    IsChecked?: boolean
}
export interface WorkEdit{
    Quantity: number
    WorkID: number
    WorkName: string

    Checked: boolean
    ConfirmedQuantity: number
}

export interface Article {
    Article: string
    ArticleID: number
    ArticleName: string
    Quantity: number
    Works: Work[]
}

export  interface Rework {
    Quantity: number
    WorkID: number
    WorkName: string

    Checked: boolean
    ConfirmedQuantity: number
}

export interface Response {
    DoctorID: number
    DoctorName: string
    DocumentID: number
    DocumentSubtypeID: number
    Editable: boolean
    MedicalOrderNumber: string
    MedicalOrderID: number
    MedicalOrderNumberCloseDate: Date
    MedicalOrderNumberCreateDate: Date
    OfficeID: number
    OfficeName: string
    PatientID: number
    PatientName: string
    ResponsibleEmployeeID: number
    ResponsibleEmployeeName: string
    Articles: Article[]
    ConfirmableWorks: ConfirmableWorks[]
    CanRegisterRework: boolean
    CanRegisterWork: boolean
    DocumentState: string

    Reworks: Rework[]
}

export async function GetTechnicianWorksDocument(orderNumber: string) {
    return await call<Response>("GetTechnicianWorksDocument", {
        DocumentID: orderNumber
    })
}
