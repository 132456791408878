import { MessageFactory } from "@react-force/models"
import { PopupMessage } from "@react-force/core"
import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function ErrorPopupMessage() {
    const { callStatusStore } = useRootStore()
    if (callStatusStore.lastError === undefined) {
        return null
    }

    let errorMessage = `${callStatusStore.lastError}`
    if (callStatusStore.lastError?.message) {
        errorMessage = callStatusStore.lastError.message
    }

    return (
        <PopupMessage
            message={MessageFactory.error(errorMessage)}
            onClose={callStatusStore.clearLastError}
        />
    )
}

export default observer(ErrorPopupMessage)
