import BackspaceIcon from "@material-ui/icons/Backspace"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import React from "react"
import Style from "./RegSearch.module.css"
import TextField from "@material-ui/core/TextField"

interface IRegSearchProps {
    updateData: any,
    label: string | null,
    offices: string[]
}

class RegSearch extends React.Component<IRegSearchProps> {
    state = {
        month: null,
        year: null,
        open: false,
        offices: this.props.offices,
        strMonth: "",
        strYear: "",
        strOffice: "",
        strNumber: "",
        strFull: "",
        keys: ["1","2","3","4","5","6","7","8","9","bs","0","cl"],
        openCode: false,
    }

    updateData = () => {
        this.props.updateData(this.state.strFull)
    }

    handleChangeOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({strOffice: event.target.value}, () => this.changeFull())
        this.changeFull()
    }
    handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({strNumber: event.target.value}, () => this.changeFull())
    }

    changeFull = () => {
        let n = this.state.strNumber
        if (n.length < 2) {
            n = n.padStart(2, "0")
        }
        let t = this.state.strMonth + "." + this.state.strYear + " - " + n + " " + this.state.strOffice
        this.setState({strFull: t}, () => {
            this.updateData()
        })
    }


    DateDialogRaw = () => {
        const d: Date = new Date()
        const months = ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек",]
        const years = [Number(d.getFullYear() - 1), Number(d.getFullYear())]

        const getMonth = (m: number) => {
            m = m + 1
            return (m.toString()).padStart(2, "0")
        }

        const handleMonth = (m: any) => {
            this.setState({month: m, strMonth: getMonth(m)}, () => this.changeFull())
        }
        const handleYear = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                year: Number(event.target.value),
                strYear: event.target.value.substring(2, 4)
            }, () => this.changeFull())
        }

        const validate = (v1:number|null, v2:number) => {
            v1 = v1 != null ? Number(v1) : -1
            return v1 === v2
        }

        return (
            <Dialog open={this.state.open}>
                <DialogTitle>Выбрать дату</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        {months.map((item, i) =>
                            <Grid key={i} item xs={4} className={Style.grid}>
                                <Paper onClick={() => handleMonth(i)} className={validate(this.state.month, i) ? Style.selected : ""}>
                                    <div className={Style.number}>{((i + 1).toString()).padStart(2, "0")}</div>
                                    <div className={Style.month}>{item}</div>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    <RadioGroup aria-label="year" name="year" value={this.state.year} onChange={handleYear} className={Style.group}>
                        {years.map((item, i) =>
                            <FormControlLabel key={i} value={item} control={<Radio/>} label={item}/>
                        )}
                    </RadioGroup>

                    {this.state.month == null || !this.state.year  ? <div className={Style.alert}>Необходимо указать месяц и год</div> : <div className={Style.alert}>&nbsp;</div>}

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth={true} color="primary" disabled={this.state.month == null || !this.state.year}
                                onClick={() => {
                                    this.setState({open: false})
                                }}>Ввод</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth={true} color="default" onClick={() => {
                                this.setState({
                                    open: false,
                                    month: null,
                                    year: null,
                                    strMonth: "",
                                    strYear: "",
                                })
                            }}>Отмена</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    labelKey = (key:string) => {
        if (key === "bs") {
            return (<BackspaceIcon className={Style.icon}/>)
        } else if (key === "cl") {
            return (<HighlightOffIcon className={Style.icon}/>)
        } else {
            return key
        }
    }

    handleKey = (key:string) => {
        if (key === "bs") {
            this.setState({strNumber: this.state.strNumber.slice(0, -1)})
        } else if (key === "cl") {
            this.setState({strNumber: ""})
        } else {
            if (this.state.strNumber.length < 3) {
                this.setState({strNumber: this.state.strNumber + key})
            }
        }
    }

    NumberDialogRaw = () => {
        return (
            <Dialog open={this.state.openCode}>
                <DialogTitle>Ввод номера</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                {this.state.keys.map((row, i) =>
                                    <Grid item xs={4} className={Style.grid} key={i}>
                                        <Paper onClick={() => this.handleKey(row)}>
                                            <div className={Style.keyboard}>{this.labelKey(row)}</div>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <div className={Style.input}>{this.state.strNumber}</div>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={this.state.strNumber.length === 0}
                                onClick={() => {
                                    this.changeFull()
                                    this.setState({openCode: false})
                                }}>Ок</Button><br/><br/><br/><br/>
                            <Button variant="contained" color="default" fullWidth={true} onClick={() => {
                                this.setState({strNumber: ""})
                                this.setState({openCode: false})
                            }}>Отмена</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <div>
                {this.NumberDialogRaw()}
                {this.DateDialogRaw()}
                <div>{this.props.label}</div>
                <Grid container spacing={1}>
                    <Grid item xs={2} className={Style.dot}>
                        <div className={Style.input} onClick={() => this.setState({open: true})}>{this.state.strMonth}</div>
                    </Grid>
                    <Grid item xs={2} className={Style.delimiter}>
                        <div className={Style.input} onClick={() => this.setState({open: true})}>{this.state.strYear}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={Style.input} onClick={() => this.setState({openCode: true})}>{this.state.strNumber}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            select
                            value={this.state.strOffice}
                            onChange={this.handleChangeOffice}
                            variant="outlined"
                            margin="dense"
                            fullWidth={true}
                        >
                            {this.state.offices.map((option, i) => (
                                <MenuItem key={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RegSearch