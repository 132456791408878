import { IconButton, Menu, MenuItem } from "@material-ui/core"
import React, { useState } from "react"
import { AccountCircle } from "@material-ui/icons"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function HeaderMenu() {
    const { authStore, routerStore } = useRootStore()
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

    const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorElement(e.currentTarget)
    const handleMenuClose = () => setAnchorElement(null)

    const handleGoHome = () => {
        handleMenuClose()
        routerStore.goTo("home")
    }

    const handleSettings = () => {
        handleMenuClose()
        routerStore.goTo("settings")
    }

    const handleHelp = () => {
        handleMenuClose()
        routerStore.goTo("help")
    }

    const handleLogin = () => {
        handleMenuClose()
        routerStore.goTo("login")
    }

    const handleLogout = () => {
        handleMenuClose()
        authStore.logout()
    }

    const route = routerStore.getCurrentRoute()
    const isHome = route && route.name === "home"

    return (
        <>
            <IconButton
                edge="start"
                onClick={handleMenuOpen}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>

            <Menu
                open={anchorElement !== null}
                anchorEl={anchorElement}
                onClose={handleMenuClose}
            >
                {!isHome && <MenuItem onClick={handleGoHome}>Личный кабинет ЗТЛ</MenuItem>}
                <MenuItem onClick={handleSettings}>Настройки</MenuItem>
                <MenuItem onClick={handleHelp}>Помощь</MenuItem>
                {!authStore.user && <MenuItem onClick={handleLogin}>Вход</MenuItem>}
                {authStore.user && <MenuItem onClick={handleLogout}>Выход</MenuItem>}
            </Menu>
        </>
    )
}

export default observer(HeaderMenu)