import { Card, CardContent } from "@material-ui/core"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
})

function UserInfo() {
    const { authStore } = useRootStore()
    const { user } = authStore
    const classes = useStyles()

    if (user === undefined) {
        return <p>Вход не выполнен</p>
    }

    return (
        <Card>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {user.specialization}
                </Typography>
                <Typography variant="h6" component="h2">
                    {user.fullName}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default observer(UserInfo)