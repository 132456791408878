// Maps the supported routes to the application pages

import CompletedWorksPage from "../pages/CompletedWorksPage"
import CurrentWorksPage from "../pages/CurrentWorksPage"
import HelpPage from "../pages/HelpPage"
import HomePage from "../pages/HomePage"
import LoginPage from "../pages/LoginPage"
import NotFoundPage from "../pages/NotFoundPage"
import NotificationPage from "../pages/NotificationPage"
import NotificationsPage from "../pages/NotificationsPage"
import OrderWorksPage from "../pages/OrderWorksPage"
import React from "react"
import RegEditWorksPage from "../pages/RegEditWorksPage"
import RegSearchPage from "../pages/RegSearchPage"
import SettingsPage from "../pages/SettingsPage"

export const viewMap = {
    home: <HomePage />,
    login: <LoginPage />,
    completedWorks: <CompletedWorksPage />,
    currentWorks: <CurrentWorksPage/>,
    order: <OrderWorksPage />,
    registrationSearсh: <RegSearchPage />,
    registrationEdit: <RegEditWorksPage />,
    notifications: <NotificationsPage />,
    notification: <NotificationPage />,
    settings: <SettingsPage />,
    notFound: <NotFoundPage />,
    help: <HelpPage />,
}
