import { Button, Card, CardContent, TextField } from "@material-ui/core"
import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function SettingsCard() {
    const { settingsStore } = useRootStore()
    const wsUrl = settingsStore.wsUrl
    const wsVersion = settingsStore.wsVersion

    const handleUrlChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        settingsStore.wsUrl = event.currentTarget.value
    }

    const handleVersionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        settingsStore.wsVersion = event.currentTarget.value
    }


    const handleResetClick = () => {
        settingsStore.reset()
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.stopPropagation()
        event.preventDefault()
        settingsStore.set({
            wsUrl: wsUrl,
            wsVersion: wsVersion,
        })

        settingsStore.saveToStorage()
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        value={wsUrl}
                        onChange={handleUrlChange}
                        fullWidth={true}
                        margin="dense"
                        label="Адрес сервера веб-сокетов"
                    />

                    <TextField
                        value={wsVersion}
                        onChange={handleVersionChange}
                        fullWidth={true}
                        margin="dense"
                        label="Версия сервера приложений"
                    />

                    <div style={{ marginTop: "1em" }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                        >
                            Обновить
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            color="secondary"
                            onClick={handleResetClick}
                            style={{ marginLeft: "1em" }}
                        >
                            Сброс
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default observer(SettingsCard)
