import { Card, CardContent } from "@material-ui/core"
import { Article } from "../../services/GetTechnicianWorksDocument"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import React from "react"
import StateIcon from "./StateIcon"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"

interface iProps {
    articles?: Article[],
    classes: any,
    label: string,
    afterContent?: any,
    isRework?: boolean
}

function ListWorks(props: iProps) {
    return (
        <React.Fragment>

            <Card className={props.classes.root}>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                        <List>
                            {props.articles?.map((article, j) => (
                                <React.Fragment key={j}>
                                    <ListItem  className={props.classes.titleWork}>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {article.ArticleName}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <List disablePadding>
                                        {article.Works.map((work, i) => (
                                            <React.Fragment key={i}>
                                                {((props.isRework && work.StateID) || (!props.isRework && work.QuantityForRegistration > 0)) && <ListItem className={props.classes.nested} key={i} alignItems="flex-start">
                                                    <ListItemIcon className={props.classes.icon}>
                                                        <StateIcon state={work.StateID}/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={10}>
                                                                    <Typography
                                                                        color="textPrimary"
                                                                    >
                                                                        {work.WorkName}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        {work.WorkTypeName ? work.WorkTypeName : ""}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={2} className={props.classes.titleWork}>
                                                                    <Typography
                                                                        align="right"
                                                                        className={props.classes.titleWork}
                                                                        color="textPrimary"
                                                                    >
                                                                        {props.isRework ? work.Quantity : work.QuantityForRegistration}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItem>}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </React.Fragment>
                            ))}
                        </List>
                    </Typography>
                    {props.afterContent}
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default observer(ListWorks)