// Stores user authentication
// Performs login/logout operations

import { RouterState, createRouterState } from "mobx-state-router"
import { connect, disconnect, updateConnectionSettings } from "../services/websockets"
import { makeAutoObservable, observable } from "mobx"
import { GetTechnicianCommonInfo } from "../services/GetTechnicianCommonInfo"
import { RegisterClientStatistics } from "../services/RegisterClientStatistics"
import { RootStore } from "./RootStore"
import { User } from "../models/User"

var md5 = require("md5")

const loginState = createRouterState("login")

export class AuthStore {
    rootStore: RootStore
    user?: User
    userSessionId?: [string, string]
    working = false
    sessionCookieName = "ultimaSid"

    // state to redirect to when logged in
    loginRedirect?: RouterState

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            user: observable,
            loginRedirect: observable.ref,
        })

        this.rootStore = rootStore
        this.loadUserSessionId()
        this.relogin()
    }

    saveSessionId = (sid: [string, string]) => {
        let cookieValue = sid[0] + ":" + sid[1]
        localStorage.setItem(this.sessionCookieName, cookieValue)
    }

    loadSessionId = () => {
        let cookieValue = localStorage.getItem(this.sessionCookieName)
        if (cookieValue) {
            let parts = cookieValue.split(/:/)
            let sid = [parts[0], parts[1]] as [string, string]

            this.saveSessionId(sid)
            return sid
        }

        return undefined
    }

    clearSessionId = () => {
        localStorage.removeItem(this.sessionCookieName)
    }

    loadUserSessionId = () => {
        const sid = this.loadSessionId()
        if (sid) {
            this.userSessionId = sid
        }
    }

    saveUserSessionId = (usid?: [string, string]) => {
        this.userSessionId = usid
        if (usid) {
            this.saveSessionId(usid)
        } else {
            this.clearSessionId()
        }
    }

    setWorking = (w: boolean) =>
        this.working = w

    setLoginRedirect = (routerState: RouterState) => {
        this.loginRedirect = routerState
    }

    setUser = (user: User) => {
        this.user = user
        if (this.loginRedirect) {
            this.rootStore.routerStore.goToState(this.loginRedirect)
        }
    }

    clearUser = () =>
        this.user = undefined

    logout = async () => {
        try {
            await disconnect()
        } finally {
            this.clearUser()
            this.saveUserSessionId()
            this.rootStore.routerStore.goToState(loginState)
        }
    }

    login = async (userName: string, password: string) => {
        updateConnectionSettings({
            userName,
            password,
        })

        await this.loginCore(userName)
    }

    relogin = async () => {
        if (this.userSessionId) {
            const [userName, sessionId] = this.userSessionId
            updateConnectionSettings({
                userName,
                sessionId,
            })

            await this.loginCore(userName)
        }
    }

    private loginCore = async (userName: string) => {
        this.setWorking(true)
        this.clearUser()
        try {
            const sessionId = await connect()
            if (sessionId) {
                this.saveUserSessionId([userName, sessionId])
                let userInfo = "platform: " + window.navigator.platform + "\n"
                    + "appVersion: " + window.navigator.appVersion + "\n"
                    + "userAgent: " + window.navigator.userAgent + "\n"
                    + "cookieEnabled: " + navigator.cookieEnabled + "\n"
                    + "javaEnabled: " + navigator.javaEnabled()
                let hash = localStorage.getItem("userHash")
                let newHash = md5(userInfo)
                if (!hash || hash !== newHash) {
                    localStorage.setItem("userHash", newHash)
                    await RegisterClientStatistics(userInfo)
                }
            }

            await this.getCommonInfo(userName)
        } catch {
            this.clearUser()
        } finally {
            this.setWorking(false)
        }
    }

    private getCommonInfo = async (userName: string) => {
        try {
            const info = await GetTechnicianCommonInfo()
            this.setUser({
                userName,
                fullName: info.EmployeeName,
                specialization: info.TechnicianSpecializationName,
                notificationCount: info.NotificationCount,
                offices: info.OfficeShortNames
            })
        } catch {
            this.setUser({
                userName,
                fullName: userName,
                specialization: "Неизвестно",
                notificationCount: 0,
                offices: []
            })
        }
    }
}
