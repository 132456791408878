import { GetMedicalOrderTechnicianInfo, GetMedicalOrderTechnicianInfoByCode, Response as OrderDocument } from "../services/GetMedicalOrderTechnicianInfo"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class SearchStore {
    rootStore: RootStore
    orderNumber?: string
    orderDocument?: OrderDocument
    notFound?: boolean
    searchInit: boolean
    orderCode?: string
    regMode?: boolean

    constructor(rootStore: RootStore) {
        this.searchInit = false
        makeAutoObservable(this, {
            orderDocument: observable.ref,
            searchInit: observable,
            orderCode: observable,
        })

        this.rootStore = rootStore

        reaction(() => this.orderNumber, () => {
            if (this.orderNumber) {
                this.loadOrder()
            } else {
                this.clearOrderDocument()
                this.searchInit = false
            }
        })

        reaction(() => this.orderCode, () => {
            if (this.orderCode) {
                this.loadOrder()
            } else {
                this.clearOrderDocument()
                this.searchInit = false
            }
        })
    }

    setRegMode = () =>
        this.regMode = true

    disableRegMode = () =>
        this.regMode = false


    setOrderCode = (code: string) =>
        this.orderCode = code

    clearOrderCode =() =>
        this.orderCode = undefined

    startSearch = () => {
        this.searchInit = true
        this.clearOrderNumber()
        this.clearOrderCode()
    }

    clearSearch = () => {
        this.searchInit = false
    }

    setOrderNumber = (n: string) =>
        this.orderNumber = n

    clearOrderNumber = () =>
        this.orderNumber = undefined

    setOrderDocument = (d: OrderDocument) => {
        this.orderDocument = d

        if (d.CanRegisterWork || (!this.regMode && d.CanNavigate)) {
            this.rootStore.routerStore.goTo("order", {
                params: {
                    orderNumber: d.DocumentID
                }
            })
        }
    }

    clearOrderDocument = () =>
        this.orderDocument = undefined

    setNotFound = (b: boolean) =>
        this.notFound = b

    clearNotFound = () =>
        this.notFound = undefined

    loadOrder = async () => {
        this.clearOrderDocument()
        this.clearNotFound()

        if (this.orderNumber || this.orderCode) {
            try {
                let d = {} as OrderDocument
                if (this.orderNumber) {
                    d = await GetMedicalOrderTechnicianInfo(this.orderNumber)
                } else if (this.orderCode) {
                    d = await GetMedicalOrderTechnicianInfoByCode(this.orderCode)
                }

                this.setOrderDocument(d)
                this.setNotFound(!d)
            } catch {
                this.clearOrderDocument()
                this.setNotFound(true)
            }
        }
    }
}