import Grid from "@material-ui/core/Grid"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"

interface iProps {
    name:string,
    code: number
}

function OrderName(props: iProps) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Typography
                    component="div"
                    variant="h6"
                    color="primary"
                >
                    {props.name}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography
                    component="div"
                    variant="h6"
                    color="primary"
                    align="right"
                >
                    {props.code}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default observer(OrderName)