import { NarrowContainer } from "@react-force/core"
import PageLayout from "../../components/PageLayout"
import React from "react"
import SettingsCard from "./SettingsCard"
import { observer } from "mobx-react"

function SettingsPage() {
    return (
        <PageLayout title="Настройки" page={SettingsPage}>
            <NarrowContainer p={2}>
                <SettingsCard />
            </NarrowContainer>
        </PageLayout>
    )
}

export default observer(SettingsPage)
