import { IReturn, IReturnVoid, JsonClient, UltimaCredentials } from "ultima-client"
import { wsUrl, wsVersion } from "./constants"

// callbacks used to track the remote calls over websockets
let trackingCallbacks = {
    startLoading: () => {},
    stopLoading: () => {},
    setLastError: (e: any) => {},
    clearLastError: () => {},
}

export function setTrackingCallbacks(callbacks: typeof trackingCallbacks) {
    console.log("Tracking callbacks are set up!", callbacks)
    trackingCallbacks = callbacks
}

// websocket connection settings
let connectionSettings = {
    url: wsUrl,
    kernelVersion: wsVersion,
    userName: "",
    password: "",
    sessionId: "",
}

export function updateConnectionSettings(newSettings: Partial<typeof connectionSettings>) {
    connectionSettings = {
        ...connectionSettings,
        ...newSettings
    }

    jsonClient = createJsonClient()
}

// websocket service client
let jsonClient = createJsonClient()

function createJsonClient() {
    const { url, kernelVersion, userName, password, sessionId } = connectionSettings

    const jsonClient = new JsonClient(url)

    jsonClient.credentials = new UltimaCredentials({
        userName,
        password,
        kernelVersion,
        sessionId,
    })

    return jsonClient
}

export async function connect() {
    try {
        return await jsonClient.connect()
    } catch (e) {
        if (String(e).indexOf("Couldn't connect to") !== -1) {
            trackingCallbacks.setLastError("Ошибка подключения к сервису")
        } else {
            trackingCallbacks.setLastError(e)
        }
        throw e
    }
}

export function disconnect() {
    return jsonClient.disconnect()
}

export async function call<T = void>(serviceName: string, message?: object): Promise<T>
export async function call<T = void>(message: IReturn<T> | IReturnVoid): Promise<T>
export async function call<T = void>(message: string | IReturnVoid | IReturn<T>, request?: object) {
    // call by name
    if (typeof message === "string") {
        return callByName<T>(message, request)
    }

    // call by message
    return callByMessage(message)
}

// call remote method by name
async function callByName<T = void>(serviceName: string, request?: Object) {
    class Request implements IReturn<T> {
        getTypeName = () => serviceName
        createResponse = () => ({}) as T
    }

    const req = new Request()
    if (request) {
        Object.assign(req, request)
    }

    return callByMessage(req) as Promise<T>
}

// call remote method by message object and track loading state
async function callByMessage(message: IReturnVoid): Promise<any>;
async function callByMessage<T>(message: IReturn<T>): Promise<T>;
async function callByMessage<T>(message: IReturn<T> | IReturnVoid) {
    console.log("Called " + (message.getTypeName ? message.getTypeName() : "unknown method"))
    try {
        trackingCallbacks.startLoading()
        const result = await jsonClient.call(message)
        trackingCallbacks.clearLastError()
        return result
    } catch (e) {
        trackingCallbacks.setLastError(e)
        throw e
    } finally {
        trackingCallbacks.stopLoading()
    }
}
