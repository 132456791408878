import {
    Article,
    ConfirmableWorks,
    GetTechnicianWorksDocument,
    Response as OrderDocument
} from "../services/GetTechnicianWorksDocument"
import { Request as ReRequest, RegisterTechnicianWork } from "../services/RegisterTechnicianWork"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class OrderWorksStore {
    rootStore: RootStore
    orderNumber?: string
    orderDocument?: OrderDocument
    notFound?: boolean
    regArticlesWorks? = [] as Article[]
    regArticlesReWorks? = [] as Article[]
    confirmableWorks = [] as ConfirmableWorks[]
    excludeWorkIds = [] as number[]
    hasError = false
    hasErrorMessage = ""
    hasSuccess = false
    registered = false

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            orderDocument: observable.ref,
            registered: observable
        })

        this.rootStore = rootStore

        reaction(() => this.orderNumber, () => {
            if (this.orderNumber) {
                this.loadOrder()
            }
        })
    }

    clearRegistered = () => {
        this.registered = false
    }

    setRegistered = () => {
        this.registered = true
    }

    setOrderNumber = (n: string) =>
        this.orderNumber = n

    clearOrderNumber = () =>
        this.orderNumber = undefined

    setOrderDocument = (d: OrderDocument) => {
        this.orderDocument = d
        this.regArticlesReWorks = []
        this.regArticlesWorks = []

        this.confirmableWorks = d.ConfirmableWorks

        d.Articles.forEach(article => {
            article.Works.forEach(work => {
                if (work.QuantityForRegistration > 0) {
                    this.regArticlesWorks?.push(article)
                }
            })
        })

        d.Articles.forEach(article => {
            article.Works.forEach(work => {
                let y = false
                this.regArticlesReWorks?.forEach(w => {
                    if (w.ArticleID === article.ArticleID) {
                        y = true
                    }
                })
                if (!y && work.StateID) {
                    this.regArticlesReWorks?.push(article)
                    return false
                }
            })
        })

        console.log(this.regArticlesWorks)
    }

    clearOrderDocument = () =>
        this.orderDocument = undefined

    setNotFound = (b: boolean) =>
        this.notFound = b

    clearNotFound = () =>
        this.notFound = undefined

    setError = (message: string) => {
        this.hasError = true
        this.hasErrorMessage = message
    }
    clearError = () => {
        this.hasError = false
        this.hasErrorMessage = ""
    }

    setSuccess = () => {
        this.hasSuccess = true
    }
    clearSuccess = () => {
        this.hasSuccess = false
    }

    loadOrder = async () => {
        this.clearOrderDocument()
        this.clearNotFound()

        if (this.orderNumber) {
            try {
                const d = await GetTechnicianWorksDocument(this.orderNumber)
                this.setOrderDocument(d)
                this.setNotFound(!d)
            } catch {
                this.clearOrderDocument()
                this.setNotFound(true)
            }
        }
    }

    registerWork = async () => {
        let req = {} as ReRequest
        req.DocumentID = this.orderDocument?.DocumentID
        req.ExcludeWorkIds = this.excludeWorkIds

        const d = await RegisterTechnicianWork(req)
        this.excludeWorkIds = []
        if (d.Success) {
            this.clearError()
            this.setSuccess()
        } else {
            this.setError(d.ErrorMessage)
            this.clearSuccess()
        }
        this.loadOrder()
    }
}