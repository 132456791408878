import React, { Fragment } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import { Button } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { Paper } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { browserHistory } from "mobx-state-router"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            paddingBottom: 0,
            marginBottom: 0,
        },
        root: {
            padding: theme.spacing(1),
        },
        icon: {
            minWidth: 28
        },
        bold: {
            fontWeight: 600
        },
        button: {
            marginRight: 10,
            marginBottom: 10,
        },
    }))

const useDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2),
        }
    }))

function RegEditWorksTable() {
    const { regEditWorksStore } = useRootStore()
    const { orderDocument: doc } = regEditWorksStore
    const [open, setOpen] = React.useState(false)
    const classes = useStyles()
    const dialogClasses = useDialogStyles()
    if (!doc|| !doc.Reworks || !doc.Reworks.length) {
        return null
    }

    const handleChange = (e:any) => {
        const isChecked = e.target.checked
        regEditWorksStore.setRegisterWork(Number(e.target.value), isChecked)
    }
    const handleFocus = (e:any) => {
        e.target.select()
    }

    const handleChangeQuantity = (e:any, id: number, max: number) => {
        let v = e.target.value
        if (v < 1) {
            v = 1
        } else if (v > max) {
            v = max
        }
        e.target.value = v
        regEditWorksStore.setConfirmedQuantity(id, v)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const goToOrder = () => {
        browserHistory.goBack()
    }
    const handleRegister = () => {
        regEditWorksStore.registerReWork()
        setOpen(false)
    }

    return (
        <Fragment>
            {regEditWorksStore.hasError &&  <Alert severity="error">{regEditWorksStore.hasErrorMessage}</Alert>}
            <Typography variant="h6" gutterBottom>
                {doc.MedicalOrderNumber}
            </Typography>
            <Paper style={{ overflow: "auto" }}>
                <List>
                    {regEditWorksStore.works.map((item, i) => (
                        <ListItem  key={i}>
                            <ListItemText
                                primary={
                                    <Grid container spacing={1}>
                                        <Grid item xs={9}>
                                            <FormControlLabel control={<Checkbox name="cc[]" color="default" value={item.WorkID} />} label={item.WorkName} onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                type="number"
                                                disabled={!item.Checked}
                                                variant="outlined"
                                                size="small"
                                                defaultValue={item.Quantity}
                                                onFocus={handleFocus}
                                                onChange={(event) => handleChangeQuantity(event, item.WorkID, item.Quantity)}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                    {!regEditWorksStore.works.length &&
                        <Fragment>Работ не найдено</Fragment>}
                </List>
            </Paper>
            <br/>
            {regEditWorksStore.isSelected &&
                <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button} fullWidth={true}>
                    Зарегистрировать переделки
                </Button>}
            <Button variant="contained" color="default" onClick={goToOrder} className={classes.button} fullWidth={true}>
                Отмена
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={dialogClasses}
            >
                <DialogTitle id="alert-dialog-title">Регистрация переделки</DialogTitle>
                <DialogContent dividers>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                    >
                        Переделки по наряду {doc.MedicalOrderNumber}
                    </Typography>
                    <List>
                        {regEditWorksStore.works.map((item, i) => (
                            <ListItem key={i}>
                                {item.Checked &&

                                    <ListItemText
                                        primary={
                                            <Grid container spacing={1}>
                                                <Grid item xs={10}>
                                                    {item.WorkName}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {item.ConfirmedQuantity}
                                                </Grid>
                                            </Grid>
                                        }
                                    />

                                }
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Отмена
                    </Button>
                    <Button onClick={handleRegister} variant="contained" color="primary" autoFocus>
                        Зарегистрировать
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default observer(RegEditWorksTable)
