import {Card, CardContent} from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CheckIcon from "@material-ui/icons/Check"
import ErrorIcon from "@material-ui/icons/Error"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import PageLayout from "../../components/PageLayout"
import PersonIcon from "@material-ui/icons/Person"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import React from "react"
import TurnedInIcon from "@material-ui/icons/TurnedIn"
import Typography from "@material-ui/core/Typography"
import {green} from "@material-ui/core/colors"
import {observer} from "mobx-react"

function HelpPage() {
    return (
        <PageLayout title="Помощь"  page={HelpPage}>
            <Typography variant="h6" gutterBottom>
                Условные обозначения
            </Typography>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Наряд
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TurnedInIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Требует утверждения"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TurnedInIcon style={{color: green[500]}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Не требует утверждения"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <RadioButtonUncheckedIcon color={"secondary"}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Открыт"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineIcon style={{color: green[500]}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Закрыт"
                            />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <br/>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Работа
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <HourglassEmptyIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Требуется подтверждение"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ErrorIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Не подтверждено (отказ)"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon style={{ color: green[500] }}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Подтверждено"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="disabled" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Не зарегистрированная работа"
                            />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </PageLayout>
    )
}

export default observer(HelpPage)
