import { List, ListItem, ListItemText, Theme, createStyles, makeStyles} from "@material-ui/core"
import { Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Moment from "moment"
import PageLayout from "../../components/PageLayout"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import parse from "html-react-parser"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            paddingBottom: 0,
            marginBottom: 0,
        },
        root: {
            padding: theme.spacing(1),
        },
        icon: {
            minWidth: 28
        },
        button: {
            marginRight: 10,
            marginBottom: 10,
        },
        stateIcon: {
            marginTop:  -theme.spacing(0.25),
            verticalAlign: "middle",
            fontSize: "1rem",
        }
    }))

function NotificationPage() {
    const { notificationsStore } = useRootStore()
    const classes = useStyles()

    const n = notificationsStore.notification

    const getPrimary = (text: any) => {
        return (
            <Typography
                variant="body1"
                color="textPrimary"
                display="block"
            >
                {text}
            </Typography>
        )
    }
    const getSecondary = (text: any) => {
        return (
            <Typography
                variant="body2"
                color="textSecondary"
                display="block"
            >
                {text}
            </Typography>
        )
    }

    const removeNotification = () => {
        notificationsStore.removeNotification(true)
    }

    return (
        <PageLayout title="Уведомление" page={NotificationPage}>
            {n && <React.Fragment>
                <List>
                    <ListItem className={classes.item}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ListItemText
                                    className={classes.item}
                                    primary={getSecondary("Дата")}
                                    secondary={getPrimary(Moment(n.RelevanceDate).format("DD.MM.YY HH:mm"))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ListItemText
                                    className={classes.item}
                                    primary={getSecondary("Категория")}
                                    secondary={getPrimary(n.Category)}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={classes.item}>
                        <Typography
                            component="div"
                            variant="h6"
                        >
                            {n.Caption}
                        </Typography>
                    </ListItem>
                </List>
                <Typography
                    component="div"
                    variant="body2"
                >
                    {n.NotificationHtml ? parse(n.NotificationHtml) : n.NotificationText}
                </Typography>
                <br/><br/>
                <Button variant="contained" color="secondary" onClick={removeNotification} className={classes.button} fullWidth={true}>
                    Удалить
                </Button>
            </React.Fragment>}
        </PageLayout>
    )
}

export default observer(NotificationPage)
