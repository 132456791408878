import { Card, CardContent } from "@material-ui/core"
import LoadingDocuments from "../../components/RegSearch/LoadingDocuments"
import { NarrowContainer } from "@react-force/core"
import PageLayout from "../../components/PageLayout"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react-lite"

function RegSearchPage() {
    return (
        <PageLayout title="Регистрация работы" page={RegSearchPage}>
            <Typography
                component="div"
                variant="h6"
                align={"center"}
            >
                Регистрация нового наряда
            </Typography>
            <NarrowContainer>
                <Card>
                    <CardContent>
                        <LoadingDocuments buttonLabel={"Регистрация"} regMode={true} />
                    </CardContent>
                </Card>
            </NarrowContainer>
        </PageLayout>
    )
}

export default observer(RegSearchPage)
