import { IReturn } from "ultima-client"
import { call } from "./websockets"

export const ApprovedStateID = 91968

export class Request implements IReturn<ActualTechnicianWorksDocument[]> {
    getTypeName = () => "GetActualTechnicianWorksDocuments"
    createResponse = () => [] as ActualTechnicianWorksDocument[]
}

export interface Work {
    Quantity: number
    StateID: number
    StateName: string
    WorkName: string
    WorkTypeName: string|null
}

export interface ActualTechnicianWorksDocument {
    MedicalOrderNumber: string
    MedicalOrderID: number
    DocumentID: number
    DocumentSubtypeID: number
    Works: Work[]
}

export async function GetActualTechnicianWorksDocuments() {
    const request = new Request()
    return await call(request)
}
