import React, {useEffect} from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import ViewModeFilter from "./ViewModeFilter"
import ViewModeFull from "./ViewModeFull"
import ViewModeReport from "./ViewModeReport"
import ViewModeSmall from "./ViewModeSmall"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginBottom: 10,
        },
        child: {
            padding: 0,
            paddingBottom: "0!important",
        },
        title: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        titleBottom: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            borderBottom: "1px solid #999999"
        },
        nested: {
            padding: 0,
            paddingLeft: theme.spacing(1),
        },
        icon: {
            minWidth: 28,
            marginTop:  theme.spacing(0.5),
        }
    }),
)

function CompletedWorksTable() {
    const { completedWorksStore: ws, routerStore } = useRootStore()
    const workDocuments = ws.filter ? ws.filtered : ws.works
    const workDocumentsReport = ws.reports
    const workDocumentsReportPending = ws.reportsPending
    const classes = useStyles()

    const refs = workDocuments?.reduce((acc:any, value) => {
        acc[value.DocumentID] = React.createRef()
        return acc
    }, {})

    useEffect(() => {
        if (refs[ws.scrollTo] && refs[ws.scrollTo].current) {
            refs[ws.scrollTo].current.scrollIntoView({
                block: "start",
            })
        }
    }, [ws, refs])

    if (!workDocuments) {
        return null
    }

    const handleEntityClicked = (id: number) => {
        routerStore.goTo("order", {
            params: {
                orderNumber: id
            }
        })
    }

    const setFilter = (id: number, name: string, pending: boolean) => {
        ws.setFilter(id, name, pending)
    }

    return (
        <div>
            {ws.showMode === "full" && <ViewModeFull refs={refs} documents={workDocuments} classes={classes} filter={ws.filter} onClick={handleEntityClicked}/>}
            {ws.showMode === "small" && <ViewModeSmall refs={refs} documents={workDocuments} classes={classes} onClick={handleEntityClicked}/>}
            {ws.showMode === "report" && <ViewModeReport documents={workDocumentsReport} documents2={workDocumentsReportPending} classes={classes} onClick={setFilter}/>}
            {ws.showMode === "filter" && <ViewModeFilter pending={ws.pending} refs={refs} documents={workDocuments} classes={classes} filter={ws.filter} onClick={handleEntityClicked}/>}
        </div>
    )
}

export default observer(CompletedWorksTable)
