import {IReturn} from "ultima-client"
import { call } from "./websockets"

export class Request implements IReturn<TechnicianWorksDocumentsHistory[]> {
    month!: number
    year!: number

    getTypeName = () => "GetTechnicianWorksDocumentsHistory"
    createResponse = () => [] as TechnicianWorksDocumentsHistory[]
}

export interface WorkGroup {
    Quantity: number
    WorkName: string
    WorkID: number
    Pending?: boolean
}

export interface TechnicianWorksDocumentsHistory {
    Works?: WorkItem[]
    DocumentID: number
    MedicalOrderID: number
    MedicalOrderNumber: string
    Pending?: boolean
}

export interface WorkItem {
    Quantity: number
    WorkName: string
    WorkID: number
}

export async function GetTechnicianWorksDocumentsHistory(year: number, month: number) {
    const request = new Request()
    request.month = month
    request.year = year

    return await call(request)
}
