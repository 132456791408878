import { ListItem, ListItemText } from "@material-ui/core"
import React, { ReactElement } from "react"
import { useRootStore } from "../../contexts"

interface ICommandProps {
    text: string
    route: string
    icon: ReactElement
}

function ListCommandItem(props: ICommandProps) {
    const { authStore, routerStore } = useRootStore()

    const handleClick = () => {
        if (props.route === "login") {
            authStore.setLoginRedirect(routerStore.routerState)
        }

        routerStore.goTo(props.route)
    }

    return (
        <ListItem button={true}>
            {props.icon}
            <ListItemText
                primary={props.text}
                onClick={handleClick}
                style={{ marginLeft: 10 }}
            />
        </ListItem>
    )
}

export default ListCommandItem
