import Loading from "./Loading"
import React from "react"

function LoadingOrder(props: { orderNumber: string }) {
    return (
        <Loading
            message={<>
                Загрузка наряда
                <br/>
                {props.orderNumber}
                ...
            </>}
        />
    )
}

export default LoadingOrder
