import {call} from "./websockets"

export interface Work {
    WorkID: number
    Quantity: number
}

export interface Request {
    DocumentID?: number
    Works: Work[]
}

export interface Response {
    ErrorMessage: string
    Success: boolean
}

export async function RegisterTechnicianRework(request: Request) {
    return await call<Response>("RegisterTechnicianRework", request)
}