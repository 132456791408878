import React, { Fragment } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core"
import { CenteredMessage } from "@react-force/core"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import OrderWorksTable from "./OrderWorksTable"
import PageLayout from "../../components/PageLayout"
import TurnedInIcon from "@material-ui/icons/TurnedIn"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        item: {
            paddingBottom: 0,
            marginBottom: 0,
        },
        root: {
            padding: theme.spacing(1),
        },
        icon: {
            minWidth: 28
        },
        button: {
            marginRight: 10,
            marginBottom: 10,
        },
        stateIcon: {
            marginTop:  -theme.spacing(0.25),
            verticalAlign: "middle",
            fontSize: "1rem",
        }
    }))

function OrderWorksPage() {
    const { callStatusStore, orderWorksStore, routerStore } = useRootStore()
    const { orderNumber } = routerStore.routerState.params
    const classes = useStyles()

    let content = (
        <CenteredMessage>
            {callStatusStore.callInProgress && `Загрузка наряда ${orderNumber}...`}
        </CenteredMessage>
    )

    const doc = orderWorksStore.orderDocument
    let title = "..."

    if (doc) {
        title = doc?.MedicalOrderNumber
        const dateOpen = new Date(doc.MedicalOrderNumberCreateDate).toLocaleDateString()
        const dateClose = doc.MedicalOrderNumberCloseDate ? new Date(doc.MedicalOrderNumberCloseDate).toLocaleDateString() : null
        const state = doc.MedicalOrderNumberCloseDate || false

        const getPrimary = (text: any) => {
            return (
                <Typography
                    variant="body1"
                    color="textPrimary"
                    display="block"
                >
                    {text}
                </Typography>
            )
        }
        const getSecondary = (text: any) => {
            return (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    display="block"
                >
                    {text}
                </Typography>
            )
        }

        content = (
            <Fragment>
                <List>
                    <ListItem className={classes.item}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <Typography
                                    component="div"
                                    variant="h6"
                                >
                                    {doc.MedicalOrderNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    component="div"
                                    variant="h6"
                                    align="right"
                                >
                                    {doc.MedicalOrderID}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {(doc.DocumentState && doc.DocumentState !== "") && <Typography variant="caption" color="textSecondary">
                                    <TurnedInIcon color="secondary" className={classes.stateIcon}/>
                                    {doc.DocumentState}
                                </Typography>}
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={classes.item}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ListItemText
                                    className={classes.item}
                                    primary={getSecondary("Дата открытия")}
                                    secondary={getPrimary(dateOpen)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {state &&
                                <ListItemText
                                    className={classes.item}
                                    primary={getSecondary("Дата закрытия")}
                                    secondary={getPrimary(dateClose)}
                                />
                                }
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={classes.item}>
                        <ListItemText
                            className={classes.item}
                            primary={getSecondary("Врач")}
                            secondary={getPrimary(doc.DoctorName)}
                        />
                    </ListItem>
                    <ListItem className={classes.item}>
                        <ListItemText
                            className={classes.item}
                            primary={getSecondary("Пациент")}
                            secondary={getPrimary(doc.PatientName)}
                        />
                    </ListItem>
                    <ListItem className={classes.item}>
                        <ListItemText
                            className={classes.item}
                            primary={getSecondary("Ответственный техник")}
                            secondary={getPrimary(doc.ResponsibleEmployeeName)}
                        />
                    </ListItem>
                </List>
                <OrderWorksTable />
            </Fragment>
        )
    }

    return (
        <PageLayout title={title} page={OrderWorksPage}>
            {content}
        </PageLayout>
    )
}

export default observer(OrderWorksPage)
