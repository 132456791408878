import { HistoryAdapter, browserHistory } from "mobx-state-router"
import { RootStore } from "./stores/RootStore"
import { configure } from "mobx"
import { initSessionCookie } from "ultima-client"

// enforce using actions to modify observable components
function initMobX() {
    configure({
        enforceActions: "observed" // "always" catches violations within the RouterStore
    })
}

export function initApp() {
    initMobX()
    initSessionCookie()
    console.log("Application is started")

    const rootStore = new RootStore()
    const { routerStore } = rootStore

    // sync router store with browser history
    const historyAdapter = new HistoryAdapter(routerStore, browserHistory)
    historyAdapter.observeRouterStateChanges()

    // debugging
    const win = window as any
    win["__MOBX_ROOT_STORE__"] = rootStore
    return rootStore
}
