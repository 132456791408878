import LoginCard from "./LoginCard"
import { NarrowContainer } from "@react-force/core"
import PageLayout from "../../components/PageLayout"
import React from "react"
import UserInfo from "../../components/UserInfo"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function LoginPage() {
    const { authStore } = useRootStore()

    return (
        <PageLayout title="Вход в систему" page={LoginPage}>
            <NarrowContainer p={2}>
                {authStore.user ?
                    <UserInfo /> :
                    <LoginCard />
                }
            </NarrowContainer>
        </PageLayout>
    )
}

export default observer(LoginPage)
