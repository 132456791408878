import React, { ReactNode, useEffect } from "react"
import { ScrollingContainer, ViewVerticalContainer } from "@react-force/core"
import ErrorPopupMessage from "./ErrorPopupMessage"
import { Header } from "../Header"
import { LinearProgress } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: string
    page: () => any
    children?: ReactNode | ReactNode[]
    className?: string
}

function PageLayout(props: IProps) {
    // use page title to produce readable browser history
    useEffect(() => {
        if (document && props.title) {
            document.title = props.title
        }
    })

    const { callStatusStore } = useRootStore()

    let progress = <LinearProgress variant="indeterminate" />
    if (!callStatusStore.callInProgress) {
        progress = <LinearProgress variant="determinate" value={0} style={{ opacity: 0 }} />
    }

    return (
        <ViewVerticalContainer minHeight={0}>
            <Header {...props} />

            {progress}

            <ScrollingContainer p={2} className={props.className}>
                {props.children}
            </ScrollingContainer>

            <ErrorPopupMessage />
        </ViewVerticalContainer>
    )
}

export default observer(PageLayout)
