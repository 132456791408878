import { call } from "./websockets"

export interface Response {
    Success: boolean
}

export async function AcknowledgeNotification(id?: number) {
    return await call<Response>("AcknowledgeNotification", {
        ID: id
    })
}
