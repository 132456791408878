import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import { Button } from "@material-ui/core"
import { ConfirmableWorks } from "../../services/GetTechnicianWorksDocument"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import ListWorks from "./ListWorks"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { enableReWork } from "../../services/constants"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginBottom: 10,
        },
        child: {
            padding: 0,
            paddingBottom: "0!important",
        },
        title: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        titleWork: {
            padding: 0,
        },
        nested: {
            padding: 0,
        },
        icon: {
            minWidth: 28,
            marginTop:  theme.spacing(0.5),
        },
        button: {
            marginRight: 10,
            marginBottom: 10,
        },
    }),
)

const useDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2),
        }
    }))

function OrderWorksTable() {
    const { orderWorksStore, routerStore } = useRootStore()

    const [open, setOpen] = React.useState({
        dialogOpen: false,
        selectedElement: {} as ConfirmableWorks
    })

    const articles = orderWorksStore.orderDocument?.Articles
    const classes = useStyles()
    const dialogClasses = useDialogStyles()


    if (!articles) {
        return null
    }

    const goToReWork = () => {
        routerStore.goTo("registrationEdit", {
            params: {
                orderNumber: orderWorksStore.orderDocument?.DocumentID
            }
        })
    }

    const confirmRegWorks = () => {
        let ex = true

        for (let key in orderWorksStore.confirmableWorks) {
            if (!orderWorksStore.confirmableWorks[key].IsChecked) {
                setOpen({
                    dialogOpen: true,
                    selectedElement: orderWorksStore.confirmableWorks[key]
                })
                ex = false
                return false
            }
        }
        if (ex) {
            orderWorksStore.registerWork()
        }
    }

    const yesReg = (yes: boolean) => {
        for (let key in orderWorksStore.confirmableWorks) {
            if (orderWorksStore.confirmableWorks[key].WorkID === open.selectedElement.WorkID) {
                orderWorksStore.confirmableWorks[key].IsChecked = true
                if (!yes) {
                    orderWorksStore.excludeWorkIds.push(open.selectedElement.WorkID)
                }
                setOpen({
                    dialogOpen: false,
                    selectedElement: {} as ConfirmableWorks
                })
            }
        }
        confirmRegWorks()
    }

    const regArticlesReWorks = orderWorksStore.regArticlesReWorks && orderWorksStore.regArticlesReWorks?.length > 0
    const regArticlesWorks = orderWorksStore.regArticlesWorks && orderWorksStore.regArticlesWorks?.length > 0

    return (
        <React.Fragment>
            <Dialog
                open={open.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={dialogClasses}
            >
                <DialogTitle id="alert-dialog-title">
                    Регистрация работы
                </DialogTitle>
                <DialogContent dividers>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                    >
                        Вы будете выполнять работу {open.selectedElement.WorkName}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => yesReg(false)}>
                        Нет
                    </Button>
                    <Button color="primary" variant="contained" autoFocus onClick={() => yesReg(true)}>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>

            {orderWorksStore.registered && <Alert severity="success">Переделка успешно зарегистрирована</Alert>}

            {regArticlesReWorks && <React.Fragment>
                {orderWorksStore.hasSuccess &&  <Alert severity="success">Работа успешно зарегистрирована</Alert>}
                <ListWorks
                    articles={orderWorksStore.regArticlesReWorks}
                    label="Зарегистрированные работы"
                    classes={classes}
                    isRework={true}
                    afterContent={enableReWork && orderWorksStore.orderDocument?.CanRegisterRework && !orderWorksStore.hasSuccess &&
                    <Button variant="contained" color="secondary" onClick={goToReWork} className={classes.button} fullWidth={true}>
                        Зарегистрировать переделку
                    </Button>
                    }
                />
            </React.Fragment>}

            {regArticlesWorks && <React.Fragment>
                {orderWorksStore.hasError &&  <Alert severity="error">{orderWorksStore.hasErrorMessage}</Alert>}
                <ListWorks
                    articles={orderWorksStore.regArticlesWorks}
                    label="Доступные для регистрации"
                    isRework={false}
                    classes={classes}
                    afterContent={orderWorksStore.orderDocument?.CanRegisterWork &&
                    <Button variant="contained" color="primary" onClick={confirmRegWorks} className={classes.button} fullWidth={true}>
                        Зарегистрировать работу
                    </Button>
                    }
                />
            </React.Fragment>}
        </React.Fragment>
    )
}

export default observer(OrderWorksTable)