import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import OrderName from "../../components/OrderName"
import React from "react"
import { TechnicianWorksDocumentsHistory as Works } from "../../services/GetTechnicianWorksDocumentsHistory"
import { observer } from "mobx-react"

interface iProps {
    documents: Works[],
    classes: any,
    onClick: any,
    filter?: any,
    refs?: any,
}

function ViewModeSmall(props: iProps) {
    return (
        <List>
            {props.documents.map((item, k) => (
                <ListItem className={props.classes.titleBottom} key={k} onClick={() => props.onClick(item.DocumentID)} ref={props.refs[item.DocumentID]}>
                    <ListItemText
                        primary={<OrderName name={item.MedicalOrderNumber} code={item.MedicalOrderID}/>}
                    />
                </ListItem>
            ))}
        </List>
    )
}

export default observer(ViewModeSmall)