import { GetTechnicianWorksDocument, Response as OrderDocument, WorkEdit } from "../services/GetTechnicianWorksDocument"
import { RegisterTechnicianRework, Request, Work } from "../services/RegisterTechnicianRework"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"
import { browserHistory } from "mobx-state-router"

export class RegEditWorksStore {
    rootStore: RootStore
    orderNumber?: string
    orderDocument?: OrderDocument
    notFound?: boolean
    works: WorkEdit[]
    isSelected: boolean
    isNew: boolean
    isCross: boolean
    hasError: boolean
    hasErrorMessage: string
    registered = false

    constructor(rootStore: RootStore) {
        this.works = []
        this.isSelected = false
        this.isNew = true
        this.isCross = false
        this.hasError = false
        this.hasErrorMessage = ""
        makeAutoObservable(this, {
            orderDocument: observable.ref,
            works: observable,
            isSelected: observable,
            isCross: observable,
            hasError: observable,
            hasErrorMessage: observable,
            registered: observable
        })

        this.rootStore = rootStore

        reaction(() => this.orderNumber, () => {
            if (this.orderNumber) {
                this.loadOrder()
            }
        })
    }

    setReWork = () => {
        this.isNew = false
    }
    setWork = () => {
        this.isNew = true
    }

    checkSelected = () => {
        this.isSelected = false
        for (let key in this.works) {
            if (this.works[key].Checked) {
                this.isSelected = true
            }
        }
    }

    clearRegistered = () => {
        this.registered = false
    }

    setRegisterWork = (id: number, checked: boolean) => {
        for (let key in this.works) {
            if (this.works[key].WorkID === id) {
                this.works[key].Checked = checked
            }
        }
        this.checkSelected()
    }

    setConfirmedQuantity = (id: number, quantity: number) => {
        for (let key in this.works) {
            if (this.works[key].WorkID === id) {
                this.works[key].ConfirmedQuantity = quantity
            }
        }
    }

    setOrderNumber = (n: string) =>
        this.orderNumber = n

    clearOrderNumber = () =>
        this.orderNumber = undefined

    setOrderDocument = (d: OrderDocument) =>
        this.orderDocument = d

    clearOrderDocument = () =>
        this.orderDocument = undefined

    setNotFound = (b: boolean) =>
        this.notFound = b

    clearNotFound = () =>
        this.notFound = undefined

    completeWorks = (d: OrderDocument) => {
        this.works = []
        this.isSelected = false

        d.Reworks.forEach(work => {
            work.ConfirmedQuantity = work.Quantity
            this.works.push(work)
        })
    }

    registerReWork = async () => {
        if (this.isSelected) {
            let req = {} as Request
            req.DocumentID = this.orderDocument?.DocumentID
            req.Works = []
            for (let key in this.works) {
                if (this.works[key].Checked) {
                    let w:Work = {} as Work
                    w.WorkID = this.works[key].WorkID
                    w.Quantity = this.works[key].ConfirmedQuantity
                    req.Works.push(w)
                }
            }

            const d = await RegisterTechnicianRework(req)
            if (d.Success) {
                this.registered = true
                this.clearOrderDocument()
                this.clearNotFound()
                this.clearError()
                browserHistory.goBack()
            } else {
                this.setError(d.ErrorMessage)
            }
        }
    }

    setError = (message: string) => {
        this.hasError = true
        this.hasErrorMessage = message
    }
    clearError = () => {
        this.hasError = false
        this.hasErrorMessage = ""
    }

    loadOrder = async () => {
        this.clearOrderDocument()
        this.clearNotFound()
        this.clearError()
        if (this.orderNumber) {
            try {
                const d = await GetTechnicianWorksDocument(this.orderNumber)
                this.completeWorks(d)
                this.setOrderDocument(d)
                this.setNotFound(!d)
            } catch {
                this.clearOrderDocument()
                this.setNotFound(true)
            }
        }
    }
}