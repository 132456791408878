import { call } from "./websockets"

export interface Technician {
    Name: string
    IsResponsible: boolean
}

export interface Response {
    DocumentID: number
    Doctor: string
    Patient: string
    CanNavigate: boolean
    CanRegisterWork: boolean
    MedicalOrderNumber: string
    Technicians: Technician[]
    MedicalOrderID: number
}

export async function GetMedicalOrderTechnicianInfo(orderNumber: string) {
    return await call<Response>("GetMedicalOrderTechnicianInfo", {
        MedicalOrderNumber: orderNumber
    })
}

export async function GetMedicalOrderTechnicianInfoByCode(orderCode: string) {
    return await call<Response>("GetMedicalOrderTechnicianInfo", {
        MedicalOrderID: orderCode
    })
}