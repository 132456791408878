// Combines all application stores in one, including the router store
// This store can be imported using the useRootStore() hook

import { RouterStore, createRouterState } from "mobx-state-router"
import { AuthStore } from "./AuthStore"
import { CallStatusStore } from "./CallStatusStore"
import { CompletedWorksStore } from "./CompletedWorksStore"
import { CurrentWorksStore } from "./CurrentWorksStore"
import { NotificationsStore } from "./NotificationsStore"
import { OrderWorksStore } from "./OrderWorksStore"
import { RegEditWorksStore } from "./RegEditWorksStore"
import { RegSearchOrderStore } from "./RegSearchOrderStore"
import { SearchStore } from "./SearchStore"
import { SettingsStore } from "./SettingsStore"
import { autorun } from "mobx"
import { routes } from "./routes"

const notFound = createRouterState("notFound")

export class RootStore {
    public callStatusStore = new CallStatusStore(this)
    public completedWorksStore = new CompletedWorksStore(this)
    public currentWorksStore = new CurrentWorksStore(this)
    public orderWorksStore = new OrderWorksStore(this)
    public regSearchOrderStore = new RegSearchOrderStore(this)
    public regEditWorksStore = new RegEditWorksStore(this)
    public notificationsStore = new NotificationsStore(this)
    public settingsStore = new SettingsStore(this)
    public searchStore = new SearchStore(this)

    // authStore should start up after settingStore to make sure
    // that server settings are loaded before the attempt to relogin
    public authStore = new AuthStore(this)

    // pass root store as an option to the router store
    public routerStore = new RouterStore(routes, notFound, {
        rootStore: this
    })

    constructor () {
        autorun(() => console.log("Entered route: " + this.routerStore.routerState.routeName))
    }
}
