import { makeAutoObservable, observable, toJS } from "mobx"
import { RootStore } from "./RootStore"
import { updateConnectionSettings } from "../services/websockets"

export interface ISettings {
    wsUrl: string,
    wsVersion: string,
}

export class SettingsStore {
    rootStore: RootStore
    wsUrl: string
    wsVersion: string

    constructor(rootStore: RootStore) {
        this.wsUrl = ""
        this.wsVersion = ""
        makeAutoObservable(this, {
            wsUrl: observable.ref,
            wsVersion: observable.ref
        })
        this.rootStore = rootStore
        this.loadFromStorage()
    }

    loadSettings = () => {
        fetch("/setting.json", {
            headers: {
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Expires": "0"
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.wsUrl = result.wsUrl
                    this.wsVersion = result.wsVersion
                    updateConnectionSettings({
                        url: result.wsUrl,
                        kernelVersion: result.wsVersion,
                    })
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    reset = () => {
        this.loadSettings()
    }

    set = (settings: ISettings) => {
        this.wsUrl = settings.wsUrl
        this.wsVersion = settings.wsVersion

        // recreate the websocket client
        updateConnectionSettings({
            url: settings.wsUrl,
            kernelVersion: settings.wsVersion,
        })
    }

    loadFromStorage = () => {
        const settings = localStorage.getItem("ws.settings")
        if (settings) {
            const parsed = JSON.parse(settings) as ISettings
            this.set(parsed)
        } else {
            this.loadSettings()
        }
    }

    saveToStorage = () => {
        localStorage.setItem("ws.settings", JSON.stringify(toJS({wsUrl: this.wsUrl, wsVersion: this.wsVersion})))
    }
}
