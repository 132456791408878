import { Badge, IconButton, createStyles, makeStyles } from "@material-ui/core"
import { Button } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Notifications } from "@material-ui/icons"
import React from "react"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react-lite"
import parse from "html-react-parser"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles(theme =>
    createStyles({
        rightMargin: {
            marginRight: theme.spacing(1),
        },
        paper: {
            margin: theme.spacing(2),
        }
    }))

function NotificationButton() {
    const classes = useStyles()
    const { authStore, routerStore, notificationsStore } = useRootStore()
    const [pin, setPin] = React.useState("")

    if (!authStore.user) {
        return null
    }

    const handleClick = () => routerStore.goTo("notifications")

    const count = notificationsStore.notificationInfo ? notificationsStore.notificationInfo?.UnreadNotifications.length : 0
    const alerts = notificationsStore.notificationInfo && (notificationsStore.notificationInfo?.Alerts.length > 0 || notificationsStore.notificationInfo?.Notices.length > 0) && notificationsStore.immediateNotification.length > 0
    const currentElement = notificationsStore.currentImmediateNotification

    const handleRead = () => {
        if (currentElement?.EmployeeNotificationTypeID === 1) {
            notificationsStore.isReadImmediatleByPin(Number(pin))
        } else {
            notificationsStore.isReadImmediatle()
        }
    }

    const handleChangePin = (e:any) => {
        const { target: { value } } = e
        setPin(value)
    }

    return (
        <React.Fragment>
            <IconButton color="inherit" onClick={handleClick} className={classes.rightMargin}>
                {count > 0 && <Badge badgeContent={count} color="secondary">
                    <Notifications />
                </Badge>}
                {count === 0 && <Notifications />}
            </IconButton>
            {alerts && currentElement && <React.Fragment>
                <Dialog
                    open={notificationsStore.immediateNotification.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={classes}
                >
                    <DialogTitle id="alert-dialog-title">
                        Уведомление
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            <Typography
                                variant="h6"
                                color="textPrimary"
                            >
                                {currentElement.Caption}
                            </Typography>
                            {parse(currentElement.NotificationHtml)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {currentElement.EmployeeNotificationTypeID === 1 &&
                            <TextField
                                error={notificationsStore.errorImmediate}
                                onChange={handleChangePin}
                                defaultValue={pin}
                                size="small"
                                label="ПИН-код"
                                variant="outlined"
                                type="password"
                                helperText={notificationsStore.errorImmediate ? "Неверный ПИН-код" : ""}
                            />
                        }
                        <Button variant="contained" color="primary" onClick={handleRead}>
                            Ок
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>}
        </React.Fragment>
    )
}

export default observer(NotificationButton)
