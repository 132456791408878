import { CenteredMessage } from "@react-force/core"
import React from "react"

function Loading(props: { message: React.ReactNode }) {
    const message = props.message || "Загрузка..."
    return (
        <CenteredMessage>
            {message}
        </CenteredMessage>
    )
}

export default Loading
