import { call } from "./websockets"

export interface Response {
    Success: boolean
}

export async function AcknowledgeNotificationByPIN(id: number, pin: number) {
    return await call<Response>("AcknowledgeNotificationByPIN", {
        ID: id,
        PIN: pin
    })
}
