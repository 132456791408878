import { call } from "./websockets"

export class Response {
    NotFound?: boolean
    IsClosed?: boolean
    NoUnregisteredWorks?: boolean
    OK?: boolean
    Comments?: string
}

export async function GetTechnicianWorksDocumentStatus(orderNumber: string) {
    return await call<Response>("GetTechnicianWorksDocumentStatus", {
        MedicalOrderNumber: orderNumber
    })
}
