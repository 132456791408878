import { Home } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import React from "react"
import { useRouterStore } from "mobx-state-router"

function HomeButton() {
    const routerStore = useRouterStore()
    const handleClick = () => routerStore.goTo("home")

    return (
        <IconButton
            color="inherit"
            aria-label="home"
            onClick={handleClick}
        >
            <Home />
        </IconButton>
    )
}

export default HomeButton
