import {call} from "./websockets"

export interface Request {
    DocumentID?: number
    WorkIds: number[]

    ExcludeWorkIds: number[]
}

export interface Response {
    ErrorMessage: string
    Success: boolean
}

export async function RegisterTechnicianWork(request: Request) {
    return await call<Response>("RegisterTechnicianWork", request)
}