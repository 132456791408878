import PageLayout from "../components/PageLayout"
import React from "react"
import { RouterLink } from "mobx-state-router"
import { observer } from "mobx-react-lite"

function NotFoundPage() {
    return (
        <PageLayout title="Страница не существует" page={NotFoundPage}>
            <p><RouterLink routeName="home">Вернуться на главную страницу</RouterLink></p>
        </PageLayout>
    )
}

export default observer(NotFoundPage)
