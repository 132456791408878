import { Card, CardContent } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import OrderName from "../../components/OrderName"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { TechnicianWorksDocumentsHistory as Works } from "../../services/GetTechnicianWorksDocumentsHistory"
import { green } from "@material-ui/core/colors"
import { observer } from "mobx-react"

interface iProps {
    documents: Works[],
    classes: any,
    onClick: any,
    filter?: any,
    refs?: any,
}

function ViewModeFull(props: iProps) {
    return (
        <List>
            {props.documents.map((item, k) => (
                <Card className={props.classes.root} key={k} ref={props.refs[item.DocumentID]}>
                    <CardContent className={props.classes.child}>
                        <ListItem className={props.classes.title} onClick={() => props.onClick(item.DocumentID)}>
                            <ListItemText
                                primary={<OrderName name={item.MedicalOrderNumber} code={item.MedicalOrderID}/>}
                            />
                        </ListItem>
                        <List disablePadding>
                            {item.Works?.map((work:any, i:any) => (
                                <React.Fragment>
                                    <ListItem className={props.classes.nested} key={i} alignItems="flex-start">
                                        <ListItemIcon className={props.classes.icon}>
                                            <CheckIcon style={{color: green[500]}}/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Grid container spacing={1}>
                                                    <Grid item xs={10}>
                                                        {work.WorkName}
                                                    </Grid>
                                                    <Grid item xs={2} className={props.classes.title}>
                                                        <Typography
                                                            align="right"
                                                            className={props.classes.title}
                                                        >
                                                            {work.Quantity}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            ))}
        </List>
    )
}

export default observer(ViewModeFull)