import { GetEmployeeNotifications, ResponseItem as Notification } from "../services/GetEmployeeNotifications"
import { GetImmediateNotifications, Response as ImmediateNotification } from "../services/GetImmediateNotifications"
import { GetNotificationsInfo, Response as NotificationInfo } from "../services/GetNotificationsInfo"
import { makeAutoObservable, observable, runInAction } from "mobx"
import { AcknowledgeNotification } from "../services/AcknowledgeNotification"
import { AcknowledgeNotificationByPIN } from "../services/AcknowledgeNotificationByPIN"
import { DeleteNotifications } from "../services/DeleteNotifications"
import { RootStore } from "./RootStore"
import { browserHistory } from "mobx-state-router"

export class NotificationsStore {
    rootStore: RootStore
    notifications: Notification[] = []
    immediateNotification: ImmediateNotification[] = []
    notification?: Notification
    notificationId?: number
    notificationInfo?: NotificationInfo
    currentImmediateNotification?: ImmediateNotification
    errorImmediate = false

    constructor(rootStore: RootStore) {
        this.notificationId = undefined
        makeAutoObservable(this, {
            notification: observable,
            notificationId: observable,
            immediateNotification: observable,
            currentImmediateNotification: observable,
            errorImmediate: observable
        })
        this.rootStore = rootStore
    }

    setNotifications = (n: Notification[]) => {
        /*n.forEach((ntf) => {
            let s = ntf.NotificationHtml.match(/<style type="text\/css">([\s\S]*?)<\/style>/gs,)
            let t = ntf.NotificationHtml.match(/(?<=<body>)([\s\S]*?)(?=<\/body>)/gs,)
            ntf.NotificationHtml = (s ? s[0] : "") + (t ? t[0] : "")
        })*/

        this.notifications = n

        if (this.notificationId) {
            n.forEach((ntf) => {
                if (ntf.ID === this.notificationId) {
                    this.notification = ntf
                }
            })
        }
    }

    clearNotifications = () =>
        this.notifications = []

    setNotification = (id:number) => {
        this.notificationId = id
        if (this.notifications) {
            this.notifications.forEach((ntf) => {
                if (ntf.ID === this.notificationId) {
                    this.notification = ntf
                }
            })
        }
        this.isRead()
    }
    clearNotification = () =>
        this.notification = undefined

    isRead = async () => {
        if (this.notification && this.notification.EmployeeNotificationTypeID !== 1 && this.notification.Unread) {
            try {
                await AcknowledgeNotification(this.notificationId)
            } catch {
            }
        }
    }

    isReadImmediatle = async () => {
        if (this.immediateNotification && this.immediateNotification.length > 0) {
            try {
                const ns = await AcknowledgeNotification(this.immediateNotification[0].ID)
                if (ns.Success) {
                    this.checkNotifications()
                }
            } catch {
            }
        }
    }
    isReadImmediatleByPin = async (pin :number) => {
        if (this.immediateNotification && this.immediateNotification.length > 0) {
            try {
                const ns = await AcknowledgeNotificationByPIN(this.immediateNotification[0].ID, pin)
                if (ns.Success) {
                    this.errorImmediate = false
                    this.checkNotifications()
                } else {
                    this.errorImmediate = true
                }
            } catch {
            }
        }
    }

    removeNotification = async(goBack: boolean) => {
        try {
            let ids = []
            ids.push(this.notificationId)
            await DeleteNotifications(ids)
            this.clearNotification()
            if (goBack) {
                browserHistory.goBack()
            } else {
                this.loadNotifications()
            }
        } catch {

        }
    }

    loadNotifications = async () => {
        try {
            const ns = await GetEmployeeNotifications()
            this.setNotifications(ns)

            const { user } = this.rootStore.authStore
            if (user) {
                runInAction(() => user.notificationCount = ns.length)
            }
        } catch {
            // handled by callStatusStore
        }
    }

    setNotificationInfo = (ns:NotificationInfo) => {
        this.notificationInfo = ns
    }

    checkNotifications = async() => {
        try {
            const ns = await GetNotificationsInfo()
            this.setNotificationInfo(ns)
            if (ns.Alerts.length || ns.Notices.length) {
                this.loadImmediateNotifications()
            } else {
                this.currentImmediateNotification = undefined
                this.immediateNotification = []
            }
        } catch {

        }
    }

    setImmediateNotifications = (n: ImmediateNotification[]) => {
        this.immediateNotification = []
        if (this.notificationInfo?.Alerts) {
            this.notificationInfo?.Alerts.forEach((alrt) => {
                n.forEach((ntf) => {
                    if (alrt === ntf.ID) {
                        /*let s = ntf.NotificationHtml.match(/<style type="text\/css">([\s\S]*?)<\/style>/gs,)
                        let t = ntf.NotificationHtml.match(/(?<=<body>)([\s\S]*?)(?=<\/body>)/gs,)
                        ntf.NotificationHtml = (s ? s[0] : "") + (t ? t[0] : "")*/
                        this.immediateNotification.push(ntf)
                    }
                })
            })
        }
        if (this.notificationInfo?.Notices) {
            this.notificationInfo?.Notices.forEach((alrt) => {
                n.forEach((ntf) => {
                    if (alrt === ntf.ID) {
                        /*let s = ntf.NotificationHtml.match(/<style type="text\/css">([\s\S]*?)<\/style>/gs,)
                        let t = ntf.NotificationHtml.match(/(?<=<body>)([\s\S]*?)(?=<\/body>)/gs,)
                        ntf.NotificationHtml = (s ? s[0] : "") + (t ? t[0] : "")*/
                        this.immediateNotification.push(ntf)
                    }
                })
            })
        }
        if (this.immediateNotification.length > 0) {
            this.currentImmediateNotification = this.immediateNotification[0]
        } else {
            this.currentImmediateNotification = undefined
        }

    }

    loadImmediateNotifications = async () => {
        try {
            const ns = await GetImmediateNotifications()
            this.setImmediateNotifications(ns)
        } catch {
            // handled by callStatusStore
        }
    }
}
